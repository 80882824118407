import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./LoginDisplay.scss";
import MainRouteViewWrapper from "../MainRouteViewWrapper";
import { LoadingSpinner } from "../../components/LoadingSpinner";

type LoginProps = WithTranslation;

class LoginDisplay extends Component<LoginProps> {
  render() {
    const { t } = this.props;

    return (
      <MainRouteViewWrapper>
        <div className="logincontainer">
          <h1 className="text">{t("loggingIn")}</h1>
          <LoadingSpinner />
        </div>
      </MainRouteViewWrapper>
    );
  }
}

export default withTranslation("common")(LoginDisplay);
