import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./OrganizationDashboard.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchNews } from "../../modules/contentful";
import { LoadingContainer } from "../../components/LoadingContainer";
import ReactMarkdown from "react-markdown";
import { OrgType } from "../../model";
import { getOrganizationDashboardUrl } from "../../routePaths";

type NewsPageProps = WithTranslation &
  RouteComponentProps<{ orgId: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface NewsPageState {
  newsLoading: boolean;
}

/*
  Fetch tool links from contentful using contentful-module. Group by topic and map through the groups
 */
class NewsPage extends Component<NewsPageProps, NewsPageState> {
  constructor(props: NewsPageProps) {
    super(props);
    this.state = {
      newsLoading: false,
    };
  }

  async componentDidMount() {
    const {
      news,
      match: {},
    } = this.props;
    window.addEventListener("refreshEntries", this.fetchNews);
    if (!news) {
      await this.fetchNews();
    }
  }

  fetchNews = async () => {
    this.setState({ newsLoading: true });
    const response = await this.props.fetchNews();
    if (response) {
      this.setState({ newsLoading: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("refreshEntries", this.fetchNews);
  }

  navigateToPath = () => {
    const {
      history,
      match: {
        params: { orgId },
      },
    } = this.props;
    history.push(getOrganizationDashboardUrl(orgId) + "/path");
  };

  render() {
    const { news, t, user } = this.props;
    const pageContent = news ? news.items[0] : null;
    return (
      <div className="orgnewspage">
        <div className="startcontainer">
          {user && (
            <h1>
              {t("newsPage.hello")} {user.firstName}!
            </h1>
          )}
        </div>
        <LoadingContainer
          loading={this.state.newsLoading}
          className="contentful-news"
        >
          <div className="news markdownbox">
            <h1>{t("clubnews.news")}</h1>
            <ReactMarkdown
              className="markdown"
              source={pageContent ? pageContent.fields.news : null}
            />
          </div>
          <div className="eventsimpactcontainer">
            <div className="events markdownbox">
              <h1>{t("clubnews.events")}</h1>
              <ReactMarkdown
                className="markdown"
                source={pageContent ? pageContent.fields.events : null}
              />
            </div>
            <div className="impact markdownbox">
              <h1>{t("clubnews.impact")}</h1>
              <ReactMarkdown
                className="markdown"
                source={pageContent ? pageContent.fields.impact : null}
              />
            </div>
          </div>
        </LoadingContainer>
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{ orgId: string }>
) => {
  return {
    currentOrgData: state.account.user
      ? state.account.user.writeAccessOrgs.find(
          (x) =>
            x.orgId === parseInt(ownProps.match.params.orgId, 10) &&
            x.type === OrgType.CLUB
        )
      : null,
    news: state.contentful.entries.news,
    user: state.account.user,
    enrollments: state.path.clubEnrollments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchNews: () => dispatch(fetchNews()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation("common")(NewsPage as any))
);
