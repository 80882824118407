import { Component } from "react";
import { ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";

interface RadioButtonSubFieldProps {
  className?: string;
  disabled?: boolean;
  formClassName?: string;
  placeholder?: string;
  fieldsAsColumns?: boolean;
  options: any;
  meta?: any;
  input: {
    name?: string;
    value: any;
    onChange(value: any): void;
  };
}

class RadioButtonSubField extends Component<RadioButtonSubFieldProps, any> {
  constructor(props: RadioButtonSubFieldProps) {
    super(props);
    this.options = this.options.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: any) {
    const { input } = this.props;
    const value = event.target.value;

    // Convert boolean strings to actual booleans
    if (value === "true") {
      input.onChange(true);
    } else if (value === "false") {
      input.onChange(false);
    } else {
      input.onChange(value);
    }
  }

  getRandomName() {
    return Math.random().toString(36).substr(2, 5);
  }

  options() {
    const { className, options, input, disabled } = this.props;
    const randomName = this.getRandomName();

    const buttons = () =>
      options.map((option, key) => {
        let labelClassName =
          disabled || option.disabled ? "radio-button-disabled " : "";

        if (options.length >= 2) {
          if (key === 0) {
            labelClassName += "label-first";
          } else if (key === options.length - 1) {
            labelClassName += "label-last";
          }
        } else {
          if (key === 0) {
            labelClassName += "label-first";
          }
        }
        return (
          <span
            key={this.getRandomName()}
            style={{ display: "flex", flex: "1 1 100%" }}
          >
            <input
              type="radio"
              id={randomName + option.key}
              name={input.name + option.key}
              value={option.key}
              onChange={this.onChange}
              checked={input.value === option.key}
              disabled={disabled || option.disabled}
            />
            <label className={labelClassName} htmlFor={randomName + option.key}>
              {option.value}
            </label>
          </span>
        );
      });

    const divClassName = className
      ? `radio-button-as-button ${className}`
      : "radio-button-as-button";

    return (
      <div className={divClassName} style={{ flex: 2 }}>
        {buttons()}
      </div>
    );
  }

  render() {
    const { formClassName, meta, placeholder } = this.props;

    return (
      <FormGroup
        controlId={placeholder}
        validationState={
          meta && meta.invalid && meta.error && meta.touched
            ? "error"
            : "success"
        }
        className={formClassName}
      >
        {placeholder ? (
          <ControlLabel className="subfield">{placeholder}</ControlLabel>
        ) : null}
        {this.options()}

        {meta && meta.invalid && meta.error && meta.touched ? (
          <HelpBlock>{meta.error}</HelpBlock>
        ) : null}
      </FormGroup>
    );
  }
}

export default RadioButtonSubField;
