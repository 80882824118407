import { PureComponent } from "react";
import * as Spinner from "react-spinkit";
import "./LoadingSpinner.scss";

export class LoadingSpinner extends PureComponent<{}> {
  render() {
    return (
      <div className="spinnercontainer">
        <Spinner name="three-bounce" fadeIn={"none"} color={"steelblue"} />
      </div>
    );
  }
}
