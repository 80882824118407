import { Fragment, Component } from "react";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";
import { TahtiseuraListField } from "../../Fields/TahtiseuraListField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class AdultsSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedPath"
              tkey="shared.sportcontent.path"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedCoaching"
              tkey="shared.sportcontent.coaching"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedPositive"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="sportcontentSharedNegative"
              placeholder="shared.sportcontent.placeholder"
              fieldTranslated="shared.sportcontent.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.center.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="centerSuggestions"
              tkey="adults.sportcontent.center.suggestions"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerLightevents"
              tkey="adults.sportcontent.center.lightEvents"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerBeginners"
              tkey="adults.sportcontent.center.beginners"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerEnableCompetitions"
              tkey="adults.sportcontent.center.enableCompetitions"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerAccessibility"
              tkey="adults.sportcontent.center.accessibility"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="centerWelcoming"
              tkey="adults.sportcontent.center.welcoming"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="centerPositive"
              placeholder="adults.sportcontent.center.placeholder"
              fieldTranslated="adults.sportcontent.center.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.center.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="centerNegative"
              placeholder="adults.sportcontent.center.placeholder"
              fieldTranslated="adults.sportcontent.center.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "adultsCenter")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("adults.sportcontent.versatility.formHeader")}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityMethodical"
              tkey="adults.sportcontent.versatility.methodical"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityContent"
              tkey="adults.sportcontent.versatility.content"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityWellBeing"
              tkey="adults.sportcontent.versatility.wellBeing"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilitySafe"
              tkey="adults.sportcontent.versatility.safe"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilityCustomization"
              tkey="adults.sportcontent.versatility.customization"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="versatilitySelfMotivation"
              tkey="adults.sportcontent.versatility.selfMotivation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.positive")}
            </div>
            <TahtiseuraListField
              t={t}
              name="versatilityPositive"
              placeholder="adults.sportcontent.versatility.placeholder"
              fieldTranslated="adults.sportcontent.versatility.positive"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("adults.sportcontent.versatility.negative")}
            </div>
            <TahtiseuraListField
              t={t}
              name="versatilityNegative"
              placeholder="adults.sportcontent.versatility.placeholder"
              fieldTranslated="adults.sportcontent.versatility.negative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "adultsVersatility"
            )}
          />
        </ExpandingContentRowHeader>
      </Fragment>
    );
  }
}

export default AdultsSportContentPart as any;
