import { Fragment, Component } from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthResourcesPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <Fragment>
        <ExpandingContentRowHeader
          title={t("shared.resources.finances.formHeader")}
          open={true}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="financesBalance"
              tkey="shared.resources.finances.balance"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesFundraising"
              tkey="shared.resources.finances.fundraising"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesModeration"
              tkey="shared.resources.finances.moderation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="financesEconomic"
              tkey="shared.resources.finances.economic"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.positive")}
            </div>
            <Field
              name="financesPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.finances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"financesPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.positive")}
              fieldValue={ratingMap[get(formData, "financesPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.finances.negative")}
            </div>
            <Field
              name="financesNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.finances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"financesNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.finances.negative")}
              fieldValue={ratingMap[get(formData, "financesNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthResourcesFinances"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.resources.circumstances.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesProper"
              tkey="shared.resources.circumstances.proper"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesEquipment"
              tkey="shared.resources.circumstances.equipment"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesBestuse"
              tkey="shared.resources.circumstances.bestuse"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesInitiative"
              tkey="shared.resources.circumstances.initiative"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="circumstancesSafety"
              tkey="shared.resources.circumstances.safety"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.positive")}
            </div>
            <Field
              name="circumstancesPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.circumstances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.positive")}
              fieldValue={ratingMap[get(formData, "circumstancesPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.resources.circumstances.negative")}
            </div>
            <Field
              name="circumstancesNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.resources.circumstances.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"circumstancesNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.resources.circumstances.negative")}
              fieldValue={ratingMap[get(formData, "circumstancesNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthResourcesCircumstances"
            )}
          />
        </ExpandingContentRowHeader>
      </Fragment>
    );
  }
}

export default YouthResourcesPart as any;
