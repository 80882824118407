import { Fragment, Component } from "react";
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import i18next from "i18next";

import "./navigation.scss";
import {
  login,
  logout,
  SET_CURRENT_ORG,
  UserData,
} from "../../modules/account";
import {
  getClubDashboardUrl,
  getOrganizationDashboardUrl,
} from "../../routePaths";
import { AccessOrg, OrgType } from "../../model";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { RouteComponentProps, withRouter } from "react-router";
import HighlightNavItem from "./HighlightNavItem";
import profilesvg from "../../images/profile.svg";
import { fetchEnrollments } from "../../modules/path";
import { RESET_LIST_RECEIVED_AT } from "../../modules/auditRequest";
import { setLocale } from "../../modules/i18n";

enum NavigationMode {
  MINIMAL = "MINIMAL",
  NORMAL = "NORMAL",
}

const languages = [
  { name: "Suomi", type: "fi_FI" },
  { name: "Svenska", type: "sv_SE" },
  { name: "English", type: "en_GB" },
];

export interface NavigationProps
  extends RouteComponentProps<{ orgId: string }>,
    WithTranslation {
  user: UserData | null;
  submitEnabled: boolean;
  handlingEnabled: boolean;
  currentOrg: number | null;
  currentOrgType: OrgType | null;
  writeAccessOrgs: AccessOrg[] | null;
  login(): void;
  logout(): void;
  setCurrentOrg(id: number): void;
  fetchEnrollments(orgId: number): void;
  resetListReceivedAt(): void;
  setLocale(locale: string): void;
}

interface NavigationState {
  isNavigationInitialized: boolean;
  mode: NavigationMode;
}

class Navigation extends Component<NavigationProps, NavigationState> {
  constructor(props: NavigationProps) {
    super(props);

    this.state = {
      isNavigationInitialized: false,
      mode: NavigationMode.NORMAL,
    };
  }

  handleActiveOrgChange = async (orgId: number, type: OrgType) => {
    this.props.setCurrentOrg(orgId);
    this.props.resetListReceivedAt();
    if (type === OrgType.CLUB) {
      await this.props.fetchEnrollments(orgId);
      this.props.history.push(getClubDashboardUrl(orgId.toString()));
    } else {
      this.props.history.push(getOrganizationDashboardUrl(orgId.toString()));
    }
  };

  handleLanguageChange = async (type: string) => {
    this.props.setLocale(type);
  };

  render() {
    const {
      user,
      login,
      t,
      logout,
      currentOrg,
      writeAccessOrgs,
      currentOrgType,
    } = this.props;
    const currentLocation = this.props.location.pathname;
    const orgData = writeAccessOrgs
      ? writeAccessOrgs.find((x) => x.orgId === currentOrg)
      : null;
    const currentOrgName = orgData ? orgData.name : null;

    let navigationItems: any = null;
    if (currentOrg) {
      if (currentOrgType === OrgType.CLUB) {
        navigationItems = (
          <Nav>
            <HighlightNavItem
              target={getClubDashboardUrl(currentOrg.toString())}
              text={t("clubnews")}
              subnav={false}
            />
          </Nav>
        );
      } else if (currentOrgType === OrgType.SPORTS_FEDERATION) {
        navigationItems = (
          <Nav>
            <HighlightNavItem
              target={getOrganizationDashboardUrl(currentOrg.toString())}
              text={t("federation")}
              subnav={false}
            />
          </Nav>
        );
      } else if (currentOrgType === OrgType.COLLECTION_AREA_ASSOCIATION) {
        navigationItems = (
          <Nav>
            <HighlightNavItem
              target={getOrganizationDashboardUrl(currentOrg.toString())}
              text={t("area")}
              subnav={false}
            />
          </Nav>
        );
      }
    }

    return (
      <Navbar collapseOnSelect={true} className="tahtiseuranav" fixedTop={true}>
        <div className="innernavcontainer">
          <Navbar.Header>
            <div className="logo">
              <div
                onClick={() => {
                  if (currentLocation !== "/") {
                    this.props.history.push("/");
                  }
                }}
              >
                {t("webservice")}
              </div>
            </div>
            <Navbar.Toggle href="" />
          </Navbar.Header>
          <Navbar.Collapse>
            {navigationItems}
            <Nav pullRight={true}>
              <NavDropdown
                title={
                  <div className="languagedropdown">
                    {languages.find((x) => x.type === i18next.language)
                      ? languages.find((x) => x.type === i18next.language)!.name
                      : "Suomi"}
                  </div>
                }
                id={"1"}
              >
                {languages.map((x) => (
                  <MenuItem
                    href=""
                    key={x.type}
                    onClick={() => this.handleLanguageChange(x.type)}
                  >
                    {x.name}
                  </MenuItem>
                ))}
              </NavDropdown>
              {user ? (
                <Fragment>
                  <NavDropdown
                    title={
                      (
                        <div className="navdropdownbutton">
                          <div className="texts">
                            <div>{`${user.firstName} ${user.lastName}`}</div>
                            {currentOrgName && (
                              <div className="currentclub">
                                {currentOrgName}
                              </div>
                            )}
                          </div>
                          <img src={profilesvg} alt="" />
                        </div>
                      ) as any
                    }
                    id={"1"}
                  >
                    {writeAccessOrgs &&
                      writeAccessOrgs.map((x) => (
                        <MenuItem
                          href=""
                          key={x.orgId}
                          onClick={() =>
                            this.handleActiveOrgChange(x.orgId, x.type)
                          }
                        >
                          <span>{x.name}</span>
                          <span className="federationname">
                            {x.federationName}
                          </span>
                        </MenuItem>
                      ))}
                    <MenuItem onClick={logout}>{t("logout")}</MenuItem>
                  </NavDropdown>
                </Fragment>
              ) : (
                <NavItem
                  eventKey={1}
                  onClick={login}
                  className="navigationlink"
                >
                  {t("login")}
                </NavItem>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    );
  }
}

const navTranslated = withTranslation("menu")(Navigation);

const mapStateToProps = (state: ReduxState) => ({
  user: state.account.user,
  currentOrg: state.account.currentOrg,
  currentOrgType: state.account.currentOrgType,
  writeAccessOrgs: state.account.user
    ? state.account.user.writeAccessOrgs
    : null,
});

const mapDispatchToProps = (dispatch) => ({
  login,
  logout,
  setCurrentOrg: (id: number) => dispatch(SET_CURRENT_ORG(id)),
  fetchEnrollments: (orgId: number) => dispatch(fetchEnrollments(orgId)),
  resetListReceivedAt: () => dispatch(RESET_LIST_RECEIVED_AT()),
  setLocale: (locale: string) => dispatch(setLocale(locale)),
});

const NavigationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(navTranslated);

export default withRouter<RouteComponentProps<{}>, any>(NavigationConnected);
