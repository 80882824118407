import "./DevelopmentItemPropsModal.scss";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { ReduxState } from "../../reducers";
import { CLOSE_DEVELOPMENT_ITEM_PROPS_MODAL } from "../../modules/developmentModals";
import { DevelopmentItemPropsForm } from "./DevelopmentItemPropsForm";
import { AuditMetaInfo } from "../../modules/auditmetainfo";

type DevelopmentItemPropsModalProps = {
  orgId: number;
  auditMetaInfo: AuditMetaInfo;
  readOnly: boolean;
  showTopSports: boolean;
};

export function DevelopmentItemPropsModal(
  props: DevelopmentItemPropsModalProps
) {
  const modalData = useSelector(
    (state: ReduxState) => state.development.itemPropsModal
  );
  const dispatch = useDispatch();

  const hideModal = () => dispatch(CLOSE_DEVELOPMENT_ITEM_PROPS_MODAL());

  return (
    <div>
      <Modal
        show={modalData.modalIsOpen}
        onHide={hideModal}
        className="centered-modal"
        dialogClassName="development-item-props-modal"
      >
        <Modal.Body>
          <DevelopmentItemPropsForm
            orgId={props.orgId}
            auditMetaInfo={props.auditMetaInfo}
            item={modalData.item}
            onCloseForm={hideModal}
            readOnly={props.readOnly}
            showTopSports={props.showTopSports}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
