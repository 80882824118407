import { Fragment, Component } from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";
import { TahtiseuraField } from "../../Fields/TahtiseuraField";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthSportContentPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <Fragment>
        <ExpandingContentRowHeader
          title={t("shared.sportcontent.formHeader")}
          className="sharedGroup"
          open={true}
        >
          {/*If FormSection has more than 1 children, it transforms to div with a class from className prop*/}
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedPath"
              tkey="shared.sportcontent.path"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="sportcontentSharedCoaching"
              tkey="shared.sportcontent.coaching"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.positive")}
            </div>
            <Field
              name="sportcontentSharedPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.positive")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedPositive")]
              }
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.sportcontent.negative")}
            </div>
            <Field
              name="sportcontentSharedNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.sportcontent.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"sportcontentSharedNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.sportcontent.negative")}
              fieldValue={
                ratingMap[get(formData, "sportcontentSharedNegative")]
              }
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "sharedSportcontent"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.passion.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackKids"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              formfieldDesc="youth.sportcontent.passion.feedback.children"
              orgId={orgId}
            />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackYouth"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              formfieldDesc="youth.sportcontent.passion.feedback.young"
              noLabel={true}
            />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackInstructors"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              formfieldDesc="youth.sportcontent.passion.feedback.coaches"
              noLabel={true}
            />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFeedbackParents"
              tkey="youth.sportcontent.passion.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
              formfieldDesc="youth.sportcontent.passion.feedback.parents"
              noLabel={true}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionAtmosphere"
              tkey="youth.sportcontent.passion.atmosphere"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionHappiness"
              tkey="youth.sportcontent.passion.happiness"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionInvolvement"
              tkey="youth.sportcontent.passion.involvement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="passionFairplay"
              tkey="youth.sportcontent.passion.fairplay"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.positive")}
            </div>
            <Field
              name="passionPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.passion.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"passionPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.positive")}
              fieldValue={ratingMap[get(formData, "passionPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.passion.negative")}
            </div>
            <Field
              name="passionNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.passion.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"passionNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.passion.negative")}
              fieldValue={ratingMap[get(formData, "passionNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPassion")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.practice.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceTotalAmount"
              tkey="youth.sportcontent.practice.totalAmount"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceEncouragement"
              tkey="youth.sportcontent.practice.encouragement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceDiverse"
              tkey="youth.sportcontent.practice.diverse"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceOrganized"
              tkey="youth.sportcontent.practice.organized"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceChoices"
              tkey="youth.sportcontent.practice.choices"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="practiceOwnImprovement"
              tkey="youth.sportcontent.practice.ownImprovement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.positive")}
            </div>
            <Field
              name="practicePositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.practice.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"practicePositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.positive")}
              fieldValue={ratingMap[get(formData, "practicePositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.practice.negative")}
            </div>
            <Field
              name="practiceNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.practice.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"practiceNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.practice.negative")}
              fieldValue={ratingMap[get(formData, "practiceNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthPractice")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.lifestyle.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleImprovement"
              tkey="youth.sportcontent.lifestyle.improvement"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleCommunication"
              tkey="youth.sportcontent.lifestyle.communication"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleCommunicationParents"
              tkey="youth.sportcontent.lifestyle.communicationParents"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleExample"
              tkey="youth.sportcontent.lifestyle.example"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="lifestyleAntidoping"
              tkey="youth.sportcontent.lifestyle.antidoping"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.positive")}
            </div>
            <Field
              name="lifestylePositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.lifestyle.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"lifestylePositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.lifestyle.positive")}
              fieldValue={ratingMap[get(formData, "lifestylePositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.lifestyle.negative")}
            </div>
            <Field
              name="lifestyleNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.lifestyle.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"lifestyleNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.lifestyle.negative")}
              fieldValue={ratingMap[get(formData, "lifestyleNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(partThemes, (x) => x.fields.type === "youthLifestyle")}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("youth.sportcontent.competition.formHeader")}
        >
          <div className="form-section">
            <TahtiseuraField
              t={t}
              options={options}
              name="competetitionInvigorating"
              tkey="youth.sportcontent.competition.invigorating"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionParticipation"
              tkey="youth.sportcontent.competition.participation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionInclusivity"
              tkey="youth.sportcontent.competition.inclusivity"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionClass"
              tkey="youth.sportcontent.competition.class"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionFeedback"
              tkey="youth.sportcontent.competition.feedback"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionEvaluation"
              tkey="youth.sportcontent.competition.evaluation"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <TahtiseuraField
              t={t}
              options={options}
              name="competitionHobbyvalue"
              tkey="youth.sportcontent.competition.hobbyvalue"
              formData={formData}
              readOnly={readOnly}
              commentEdit={commentEdit}
              ratingMap={ratingMap}
              type={type}
              orgId={orgId}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.positive")}
            </div>
            <Field
              name="competitionPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.competition.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"competitionPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.competition.positive")}
              fieldValue={ratingMap[get(formData, "competitionPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("youth.sportcontent.competition.negative")}
            </div>
            <Field
              name="competitionNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("youth.sportcontent.competition.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"competitionNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("youth.sportcontent.competition.negative")}
              fieldValue={ratingMap[get(formData, "competitionNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthCompetition"
            )}
          />
        </ExpandingContentRowHeader>
      </Fragment>
    );
  }
}

export default YouthSportContentPart as any;
