import { NavItem } from "react-bootstrap";
import { Component } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { withRouter } from "react-router";

class HighlightNavItem extends Component<any> {
  render() {
    const { location, target, text, subnav, history } = this.props;
    const currentLocation = location ? location.pathname : "";
    return (
      <NavItem
        active={
          subnav
            ? currentLocation.endsWith(target)
            : currentLocation.includes(target)
        }
        onClick={() => {
          history.push(target);
        }}
        className="navigationlink"
      >
        {text}
      </NavItem>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.account.user,
});

export default withRouter(
  connect(mapStateToProps, null)(HighlightNavItem)
) as any;
