import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReduxState } from "../../reducers";
import { RouteComponentProps, withRouter } from "react-router";
import {
  fetchAuditRequestAddInfo,
  fetchSingleAuditRequestAudit,
} from "../../modules/auditRequest";
import { Field, reduxForm } from "redux-form";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format as fnsFormat, parse as fnsParse } from "date-fns";
import DayPicker from "react-day-picker";
import { getClubDashboardUrl } from "../../routePaths";
import { connect } from "react-redux";
import { fetchSingleResponse } from "../../modules/auditor";
import AuditSummaryV1 from "../../components/Forms/V1/AuditSummaryV1";
import AuditSummaryV2 from "../../components/Forms/V2/AuditSummaryV2";
import AuditSummaryV3 from "../../components/Forms/V3/AuditSummaryV3";
import AuditSummaryV4 from "../../components/Forms/V4/AuditSummaryV4";
import AuditSummaryV5 from "../../components/Forms/V5/AuditSummaryV5";
import AuditSummaryV6 from "../../components/Forms/V6/AuditSummaryV6";

interface ComponentProps {
  initialize(formData: any): void;
}

type ClubAuditSummaryProps = ComponentProps &
  WithTranslation &
  RouteComponentProps<{
    orgId: string;
    requestId: string;
    responseId: string;
  }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ClubAuditSummary extends Component<ClubAuditSummaryProps, any> {
  constructor(props: ClubAuditSummaryProps) {
    super(props);
    this.state = {};
  }

  initForm = async () => {
    const {
      currentResponse,
      initialize,
      match: {
        params: { responseId },
      },
    } = this.props;

    let response: any = null;
    if (currentResponse && currentResponse.id.toString() === responseId) {
      response = currentResponse;
    } else {
      response = await this.props.fetchSingleResponse(responseId);
    }

    if (response) {
      initialize(response.values);
    }
  };

  componentDidMount() {
    const {
      auditRequest,
      match: {
        params: { requestId },
      },
    } = this.props;

    if (
      requestId &&
      (!auditRequest || auditRequest.id.toString() !== requestId)
    ) {
      this.props.fetchSingleAuditRequestAudit(requestId);
    }
    // always fetch additional info because it might change between route changes
    if (requestId) {
      this.props.fetchAuditRequestAddInfo(requestId);
    }

    this.initForm();
  }

  componentDidUpdate(prevProps: ClubAuditSummaryProps) {
    const { currentResponse } = this.props;

    if (currentResponse && currentResponse !== prevProps.currentResponse) {
      this.props.initialize(currentResponse.values);
    }
  }

  navigateToClubPart = () => {
    const { history } = this.props;
    history.push(getClubDashboardUrl(this.props.match.params.orgId) + "/path");
  };

  render() {
    const {
      auditRequest,
      auditRequestAddInfo,
      currentResponse,
      t,
    } = this.props;
    if (!auditRequest || !currentResponse) {
      return null;
    }
    const options = [
      {
        key: "ACCEPTED",
        value: (
          <div>
            <b>{t("auditSummary.congratulations")}</b>
            {t("auditSummary.congratulations2")}
          </div>
        ),
      },
      {
        key: "REJECTED_PRELIMINARY",
        value: (
          <div>
            <div className="text">{t("auditSummary.rejectPreliminary")}</div>
          </div>
        ),
        extra: (
          <Field
            name="agreedCorrectionDate"
            formCtrlClassName="summaryfield"
            component={(field: any) => (
              <div>
                <div>{t("auditSummary.rejectDate")}</div>
                <DayPickerInput
                  formatDate={(date, format, locale) =>
                    fnsFormat(date, format, { locale })
                  }
                  parseDate={(str, format, locale) => {
                    const parsed = fnsParse(fnsFormat(str, format, { locale }));
                    if ((DayPicker.DateUtils as any).isDate(parsed)) {
                      return parsed;
                    }
                    return undefined;
                  }}
                  format="DD.MM.YYYY"
                  placeholder={t("auditSummary.rejectPlaceholder")}
                  inputProps={{ ...field.input, disabled: true }}
                  onDayChange={(day) => console.warn(day)}
                  value={field.input.value}
                />
              </div>
            )}
            disabled={false}
          />
        ),
      },
      { key: "REJECTED", value: <div>{t("auditSummary.reject")}</div> },
    ];

    return auditRequest.formVersion === 1 ? (
      <AuditSummaryV1
        readOnly={true}
        auditor={false}
        options={options}
        navigateBack={this.navigateToClubPart}
      />
    ) : auditRequest.formVersion === 2 ? (
      <AuditSummaryV2
        readOnly={true}
        auditor={false}
        options={options}
        auditRequest={auditRequest}
        auditRequestAddInfo={auditRequestAddInfo}
        navigateBack={this.navigateToClubPart}
      />
    ) : auditRequest.formVersion === 3 ? (
      <AuditSummaryV3
        readOnly={true}
        auditor={false}
        options={options}
        auditRequest={auditRequest}
        auditRequestAddInfo={auditRequestAddInfo}
        navigateBack={this.navigateToClubPart}
      />
    ) : auditRequest.formVersion === 4 ? (
      <AuditSummaryV4
        readOnly={true}
        auditor={false}
        options={options}
        auditRequest={auditRequest}
        auditRequestAddInfo={auditRequestAddInfo}
        navigateBack={this.navigateToClubPart}
      />
    ) : auditRequest.formVersion === 5 ? (
      <AuditSummaryV5
        readOnly={true}
        auditor={false}
        options={options}
        auditRequest={auditRequest}
        auditRequestAddInfo={auditRequestAddInfo}
        navigateBack={this.navigateToClubPart}
      />
    ) : auditRequest.formVersion === 6 ? (
      <AuditSummaryV6
        readOnly={true}
        auditor={false}
        options={options}
        auditRequest={auditRequest}
        auditRequestAddInfo={auditRequestAddInfo}
        navigateBack={this.navigateToClubPart}
      />
    ) : (
      <div>
        {t("common:error.missingFormVersion", {
          version: auditRequest.formVersion,
        })}
      </div>
    );
  }
}

const translated = withTranslation("forms")(ClubAuditSummary);

const mapStateToProps = (state: ReduxState) => {
  return {
    auditRequest: state.auditRequest.auditRequest,
    auditRequestAddInfo: state.auditRequest.auditRequestAddInfo,
    currentResponse: state.auditor.currentResponse,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchSingleAuditRequestAudit: (requestId: string) =>
    dispatch(fetchSingleAuditRequestAudit(requestId)),
  fetchAuditRequestAddInfo: (requestId: string) =>
    dispatch(fetchAuditRequestAddInfo(requestId)),
  fetchSingleResponse: (responseId: string) =>
    dispatch(fetchSingleResponse(responseId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: "summaryForm" })(translated as any))
);
