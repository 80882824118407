import { WithTranslation, withTranslation } from "react-i18next";
import { Field, Fields } from "redux-form";
import { ExpandingContentRowHeader } from "../../Headers/ExpandingContentRowHeader";
import TextAreaField from "../Fields/TextAreaField";
import DateField from "../Fields/DateField";
import RadioButtonGroupField from "../Fields/RadioButtonGroupField";
import { Fragment, Component } from "react";
import { OrgType } from "../../../model";

type AuditSummaryV1Props = WithTranslation & {
  formData?: any;
  readOnly?: boolean;
  auditor: boolean;
  commentsAdded?: boolean;
  options: any;
  currentOrgType?: OrgType | null;
  handleSubmit?(values?: any): void;
  saveResponseDraft?(): void;
  saveResponseAndSubmit?(): void;
  navigateBack(): void;
};

class AuditSummaryV1 extends Component<AuditSummaryV1Props, any> {
  getHelpText = (key: string) => {
    const { t, auditor } = this.props;
    return auditor ? t(key + ".helpText") : "";
  };

  render() {
    const {
      t,
      handleSubmit,
      readOnly,
      options,
      commentsAdded,
      currentOrgType,
      auditor,
      formData,
      saveResponseAndSubmit,
      saveResponseDraft,
      navigateBack,
    } = this.props;

    return (
      <div className="partcontainer">
        <form onSubmit={handleSubmit} className="pathpart">
          <ExpandingContentRowHeader
            title={t("auditSummary.summary")}
            open={true}
            hideHideButton={true}
          >
            <div className="form-section">
              <Field
                label={t("auditSummary.thingsForImprovement.label")}
                helpText={this.getHelpText("auditSummary.thingsForImprovement")}
                name="thingsForImprovement"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.thingsForImprovement.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.criteriaNotMet.label")}
                helpText={this.getHelpText("auditSummary.criteriaNotMet")}
                name="criteriaNotMet"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.criteriaNotMet.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.feedback.label")}
                helpText={this.getHelpText("auditSummary.feedback")}
                name="feedback"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.feedback.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.ideas.label")}
                helpText={this.getHelpText("auditSummary.ideas")}
                name="ideas"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.ideas.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.orgparticipants.label")}
                helpText={this.getHelpText("auditSummary.orgparticipants")}
                name="orgparticipants"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.orgparticipants.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                label={t("auditSummary.auditparticipants.label")}
                helpText={this.getHelpText("auditSummary.auditparticipants")}
                name="auditparticipants"
                formCtrlClassName="summaryfield"
                component={TextAreaField}
                placeholder={t("auditSummary.auditparticipants.placeholder")}
                disabled={readOnly}
                maxlength={4000}
              />
              <hr />
              <Field
                name="auditDate"
                formCtrlClassName="summaryfield"
                label={t("auditSummary.auditDate.label")}
                placeholder={t("auditSummary.auditDate.placeholder")}
                component={DateField}
                disabled={readOnly}
              />
              <hr />
              <Fields
                helpText={this.getHelpText("auditSummary.decision")}
                className="summaryfield"
                names={["decision"]}
                component={RadioButtonGroupField}
                options={options}
                placeholder={t("auditSummary.decision.label")}
                disabled={readOnly}
                maxlength={4000}
              />
              {auditor &&
                formData &&
                formData.decision === "REJECTED_PRELIMINARY" && (
                  <Field
                    name="agreedCorrectionDate"
                    formCtrlClassName="summaryfield"
                    label={t("auditSummary.rejectDate")}
                    placeholder={t("auditSummary.auditDate.placeholder")}
                    component={DateField}
                    disabled={readOnly}
                  />
                )}
              {auditor && !commentsAdded && !readOnly && (
                <div className="nocommentshelp">
                  {t("auditSummary.NoCommentsHelp")}
                </div>
              )}
            </div>
          </ExpandingContentRowHeader>
          {auditor && readOnly && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "30px 0",
                color: "#00558b",
              }}
            >
              {t("auditSummary.sent")}
            </div>
          )}
          <div className="buttons summarybuttons">
            {auditor && !readOnly && (
              <Fragment>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={saveResponseAndSubmit}
                  disabled={
                    currentOrgType === OrgType.CLUB ||
                    currentOrgType === OrgType.COLLECTION_AREA_ASSOCIATION
                  }
                >
                  {t("auditSummaryButtons.send")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-secondary-blue"
                  onClick={saveResponseDraft}
                  disabled={currentOrgType === OrgType.CLUB}
                >
                  {t("auditSummaryButtons.draft")}
                </button>
              </Fragment>
            )}
            <button
              type="button"
              className="btn btn-secondary btn-secondary-blue"
              onClick={navigateBack}
            >
              {t("auditSummaryButtons.back")}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation("forms")(AuditSummaryV1);
