import * as React from "react";
import { HelpBlock } from "react-bootstrap";
import * as uuid from "uuid";
import { errorText, isInvalid } from "../../../../util/validationHelper";

interface ErrorProps {
  fieldType: ErrorFieldType;
  meta: {
    invalid: boolean;
    error: boolean;
    touched: boolean;
    dirty: boolean;
  };
  names?: string[];
  fallbackCmp?: React.ReactChild;
}

export enum ErrorFieldType {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
}

/**
 * Component for displaying errors in forms. Should work with FieldArray and Field
 * errors.
 */
const ErrorBlock: React.StatelessComponent<ErrorProps> = (props) => {
  const { invalid, error, touched, dirty } = props.meta;

  let errors: any = null;

  if (props.fieldType === ErrorFieldType.SINGLE) {
    errors =
      invalid && error && (touched || dirty) ? (
        <HelpBlock>{error}</HelpBlock>
      ) : props.fallbackCmp ? (
        props.fallbackCmp
      ) : null;
  } else if (props.fieldType === ErrorFieldType.MULTI) {
    errors = isInvalid(props.names, props) ? (
      <HelpBlock>
        {errorText(props.names, props).map((err) => {
          return <span key={uuid.v4()}>{err}</span>;
        })}
      </HelpBlock>
    ) : props.fallbackCmp ? (
      props.fallbackCmp
    ) : null;
  }

  return errors;
};

export default ErrorBlock;
