import { Switch, Route } from "react-router-dom";
import { Fragment } from "react";
import Signout from "./views/signout/Signout";
import HomePublic from "./views/home/HomePublic";
import { paths } from "./routePaths";
import ClubDashboard from "./views/dashboard/ClubDashboard";
import Path from "./views/dashboard/Path";
import News from "./views/dashboard/News";
import Tools from "./views/dashboard/Tools";
import FedNews from "./views/organization/News";
import { Clubs as FedClubs } from "./views/organization/Clubs";
import FedAuditing from "./views/organization/Auditing";
import OrgSummaryView from "./views/organization/OrgSummaryView";
import OrganizationDashboard from "./views/organization/OrganizationDashboard";
import FedPath from "./views/organization/Path";
import FedPathForm from "./views/organization/PathForm";
import Login from "./views/login/Login";
import PathForm from "./views/dashboard/PathForm";
import AuditSummary from "./views/organization/AuditSummary";
import ClubAuditSummary from "./views/dashboard/AuditSummary";
import HelpLinks from "./views/dashboard/HelpLinks";
import LoginRedirect from "./views/login/LoginRedirect";
import WithAuthentication from "./WithAuthentication";
import { ClubAttachments } from "./views/dashboard/Attachments";
import Development from "./views/clubdevelopment/Development";
import DevelopmentSummary from "./views/clubdevelopment/DevelopmentSummary";
import { OrganizationClubPathRouter } from "./OrganizationClubPathRouter";

/*
  MAIN ROUTES: These are in the top bar. Remember to use MainRouteViewWrapper in the route components
 */
export const Routes = () => (
  <Switch>
    <Route exact={true} path="/" component={HomePublic} />
    <Route path={paths.loginRedirect} component={LoginRedirect} />
    <Route path={paths.login} component={WithAuthentication(Login as any)} />
    <Route path={paths.profile} component={WithAuthentication(Login as any)} />
    <Route
      path={paths.clubDashboard}
      component={WithAuthentication(ClubDashboard as any)}
    />
    <Route
      path={paths.organizationDashboard}
      component={WithAuthentication(OrganizationDashboard as any)}
    />
    <Route path={paths.signout} component={Signout} />
  </Switch>
);

/*
  Subroutes for PathForms
 */
export const PathFormRoutes = () => {
  return (
    <Fragment>
      <Route exact={true} path={paths.clubPath} component={Path} />
      <Route path={paths.clubPathPart} component={PathForm} />
    </Fragment>
  );
};

/*
  Subroutes for ClubDashboard
 */
export const ClubDashboardRoutes = () => {
  return (
    <Fragment>
      <Route exact={true} path={paths.clubDashboard} component={News} />
      <Route
        path={paths.clubDashboard + "/:enrollId/path"}
        component={PathFormRoutes}
      />
      <Route
        path={paths.clubDashboard + "/development"}
        component={Development}
      />
      <Route
        path={paths.clubDashboard + "/development-summary"}
        component={DevelopmentSummary}
      />
      <Route path={paths.clubDashboard + "/tools"} component={Tools} />
      <Route
        path={paths.clubDashboard + "/attachments"}
        component={ClubAttachments}
      />
      <Route path={paths.clubDashboard + "/help"} component={HelpLinks} />
      <Route
        path={paths.clubDashboard + "/:enrollId/audit/:requestId/:responseId"}
        component={ClubAuditSummary}
      />
    </Fragment>
  );
};

/*
  Subroutes for OrganizationDashboard
 */
export const OrganizationDashboardRoutes = () => {
  return (
    <Fragment>
      <Route
        exact={true}
        path={paths.organizationDashboard}
        component={FedNews}
      />
      <Route
        exact={true}
        path={paths.organizationDashboard + "/clubs"}
        component={FedClubs}
      />
      <Route
        path={paths.organizationDashboard + "/auditing"}
        component={FedAuditing}
      />
      <Route
        path={paths.organizationDashboard + "/summary"}
        component={OrgSummaryView}
      />
      <Route path={paths.organizationDashboard + "/tools"} component={Tools} />
      <Route
        path={paths.organizationDashboard + "/help"}
        component={HelpLinks}
      />
      <Route
        exact={true}
        path={paths.organizationDashboard + "/path/:requestId"}
        component={FedPath}
      />
      <Route path={paths.auditPathPart} component={FedPathForm} />
      <Route
        path={paths.organizationDashboard + "/audit/:requestId/:responseId?"}
        component={AuditSummary}
      />
      <Route
        path={paths.organizationClubPath}
        component={OrganizationClubPathRouter}
      />
      <Route
        path={paths.organizationClubPath + "/development-summary"}
        render={(props) => <DevelopmentSummary {...props} readOnly={true} />}
      />
    </Fragment>
  );
};
