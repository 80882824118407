import { TFunction, TOptions } from "i18next";
import ReactMarkdown, { NodeType } from "react-markdown";

// List of Markdown escapable characters: \[]-`*_{}()#+.!
// Taken from: https://enterprise.github.com/downloads/en/markdown-cheatsheet.pdf
const escapeMarkdown = (str: string) =>
  str.replace(/[\\[\]\-`*_{}()#+.!]/g, "\\$&");

type TWithMarkdownFunction = (
  key: string | string[],
  options?: TOptions<object> & {
    markdown?: {
      /** If true, <p> tags are added for text paragraphs. */
      multiline: boolean;
      /** callback definitions for button onClick handlers */
      callbacks?: {
        [callbackName: string]: () => void;
      };
      /** the following links will be disabled, i.e. displayed only as text */
      disabledLinks?: string[];
    };
  }
) => JSX.Element;

/**
 * Wrap the given translation function (t) with ReactMarkdown to support Markdown formatting
 * in localized strings.
 *
 * @returns The wrapped translation function (returns a JSX element instead of a string)
 */
export const translateWithMarkdown = (t: TFunction): TWithMarkdownFunction => (
  key,
  options
) => {
  const { markdown: markdownOptions, ...optionsWithoutMarkdownOptions } =
    options || {};

  // Add other types here as needed. See documentation for a list.
  const allowedTypes: NodeType[] = ["strong", "emphasis", "link"];

  // If in multiline mode, allow paragraphs and lists. In single-line mode we will not allow those
  // so that they will be removed by the unwrapDisallowed option.
  if (markdownOptions && markdownOptions.multiline) {
    allowedTypes.push("paragraph", "list", "listItem");
  }

  return (
    <ReactMarkdown
      source={t(key, {
        ...optionsWithoutMarkdownOptions,
        interpolation: {
          ...(optionsWithoutMarkdownOptions &&
            optionsWithoutMarkdownOptions.interpolation),
          escapeValue: true,
          escape: escapeMarkdown,
        },
      })}
      allowedTypes={allowedTypes}
      unwrapDisallowed={true}
      renderers={{
        // Override link renderer in order to default to _blank target and noopener/noreferrer.
        link: (props) => {
          if (
            props.href &&
            markdownOptions &&
            markdownOptions.disabledLinks &&
            markdownOptions.disabledLinks.includes(props.href)
          ) {
            return props.children;
          }

          if (props.href && props.href.startsWith("button//")) {
            const callbackName = props.href.slice(8);
            const callback =
              (markdownOptions &&
                markdownOptions.callbacks &&
                markdownOptions.callbacks[callbackName]) ||
              undefined;

            return (
              <button type="button" onClick={callback}>
                {props.children}
              </button>
            );
          }

          return (
            <a href={props.href} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          );
        },
      }}
    />
  );
};
