import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import "../dashboard/ClubDashboard.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { fetchSingleAuditRequestAudit } from "../../modules/auditRequest";
import { AUDITOR_MODIFIABLE_PART_STATES, PartStatus } from "../../model";
import PathFormV1 from "../../components/Forms/V1/PathFormV1";
import PathFormV2 from "../../components/Forms/V2/PathFormV2";
import PathFormV3 from "../../components/Forms/V3/PathFormV3";
import PathFormV4 from "../../components/Forms/V4/PathFormV4";
import PathFormV5 from "../../components/Forms/V5/PathFormV5";
import PathFormV6 from "../../components/Forms/V6/PathFormV6";
import { translateWithMarkdown } from "../../util/translateWithMarkdown";
import { getOrganizationAuditFormMetaInfo } from "../../modules/formMetaInfo";
import {
  GradeOptionsList,
  YesNoOptionsList,
} from "../../components/Forms/Components/FieldGradingOptionList";
import * as Logger from "js-logger";

type PathFormRouteComponentProps = RouteComponentProps<{
  orgId: string;
  part: string;
  type: string;
  requestId: string;
}>;

type PathFormProps = PathFormRouteComponentProps &
  WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class PathForm extends Component<PathFormProps, any> {
  constructor(props: PathFormProps) {
    super(props);
    this.state = {};
    this.updateFormMetaInfoForCurrentClub = this.updateFormMetaInfoForCurrentClub.bind(
      this
    );
  }

  componentDidMount() {
    const {
      auditRequest,
      fetchSingleAuditRequestAudit,
      match: {
        params: { requestId },
      },
    } = this.props;
    if (!auditRequest) {
      fetchSingleAuditRequestAudit(requestId);
    } else {
      this.updateFormMetaInfoForCurrentClub();
    }
  }

  componentDidUpdate(prevProps: PathFormProps) {
    const { auditRequest } = this.props;

    if (prevProps.auditRequest?.clubId !== auditRequest?.clubId) {
      this.updateFormMetaInfoForCurrentClub();
    }
  }

  updateFormMetaInfoForCurrentClub() {
    const { auditRequest, getAuditFormMetaInfo } = this.props;

    if (auditRequest?.clubId) {
      getAuditFormMetaInfo(auditRequest?.clubId);
    } else {
      Logger.warn("No auditRequest or clubId", auditRequest);
    }
  }

  render() {
    const {
      match,
      auditRequest,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
    } = this.props;
    if (!auditRequest) {
      return null;
    }
    const t = translateWithMarkdown(this.props.t);
    const tWithoutMarkdown = this.props.t;
    const currentOrg = parseInt(match.params.orgId, 10);
    const readOnlyMode = true;
    const formPart = match.params.part;
    const part = auditRequest.parts.find((x) => x.key === formPart);
    const partState = part ? (PartStatus[part.state] as PartStatus) : null;
    const commentEdit =
      auditRequest.modifiableByAuditor &&
      partState != null &&
      AUDITOR_MODIFIABLE_PART_STATES.includes(partState);

    const partIsBasicCriteria = match.params.part === "basiccriteria";

    return (
      <div className="partcontainer">
        <h1>{t(`${formPart}.title`)}</h1>
        {match.params.type === "YOUTH" && match.params.part === "sportcontent" && (
          <div className="gradesWithBanner">
            <div className="youthBanner">{t("part.childRightsText")}</div>
          </div>
        )}
        <p className="description">
          {t("part.generalDescriptionOrg", { markdown: { multiline: true } })}
        </p>
        {!partIsBasicCriteria ? <GradeOptionsList /> : <YesNoOptionsList />}
        {auditRequest.formVersion === 1 ? (
          <PathFormV1
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 2 ? (
          <PathFormV2
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 3 ? (
          <PathFormV3
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 4 ? (
          <PathFormV4
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
          />
        ) : auditRequest.formVersion === 5 ? (
          <PathFormV5
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
            formMetaInfo={formMetaInfo}
            sportsFederationTranslationKeyMap={
              sportsFederationTranslationKeyMap
            }
          />
        ) : auditRequest.formVersion === 6 ? (
          <PathFormV6
            currentOrg={currentOrg}
            enrollment={null}
            activeRequest={auditRequest}
            readOnlyMode={readOnlyMode}
            commentEdit={commentEdit}
            clubMode={false}
            formPart={formPart}
            pathType={match.params.type}
            formMetaInfo={formMetaInfo}
            sportsFederationTranslationKeyMap={
              sportsFederationTranslationKeyMap
            }
          />
        ) : (
          <div>
            {tWithoutMarkdown("common:error.missingFormVersion", {
              version: auditRequest.formVersion,
            })}
          </div>
        )}
      </div>
    );
  }
}

const translated = withTranslation("forms")(PathForm);

const mapStateToProps = (state: ReduxState) => {
  const auditRequest = state.auditRequest.auditRequest;
  return {
    auditRequest: auditRequest,
    formMetaInfo: auditRequest
      ? state.organizationAuditFormMetaInfo?.organizationAuditFormMetaInfo[
          auditRequest.clubId
        ]
      : undefined,
    sportsFederationTranslationKeyMap:
      state.config.topSportsSportsFederationNameMap,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleAuditRequestAudit: (requestId: string) =>
      dispatch(fetchSingleAuditRequestAudit(requestId)),
    getAuditFormMetaInfo: (orgId: number) =>
      dispatch(getOrganizationAuditFormMetaInfo(orgId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(translated as any)
);
