import "./DevelopmentItemCard.scss";
import personIcon from "../../images/person.svg";
import { DevelopmentItem } from "../../modules/development";
import { DevelopmentItemCardFrame } from "./DevelopmentItemCardFrame";
import { useTranslation } from "react-i18next";
import { DevelopmentItemMenu } from "./DevelopmentItemMenu";
import { DevelopmentItemStatusRow } from "./DevelopmentItemStatusRow";
import { DEVELOPMENT_ITEM_TOOLTIP_ID } from "../../views/clubdevelopment/Development";

export interface DevelopmentItemCardProps {
  item: DevelopmentItem;
  preview?: boolean;
  opacity?: number;
  innerRef?: any;
  itemListElement?: HTMLElement;
  readOnly: boolean;
}

export function DevelopmentItemCard(props: DevelopmentItemCardProps) {
  const { t } = useTranslation("clubDevelopment");

  const prioritized = props.item.priority && props.item.priority > 0;
  const height = props.preview ? 50 : prioritized ? 140 : 100;
  const assigneeArray = props.item.assignees;
  const assignee =
    assigneeArray &&
    assigneeArray.length > 0 &&
    assigneeArray.find((assignee) => assignee.trim().length > 0);

  return (
    <DevelopmentItemCardFrame
      itemProps={props.item}
      className={`development-item-card ${
        prioritized ? "development-item-card-prioritized" : ""
      }`}
      height={height}
      opacity={props.opacity}
      innerRef={props.innerRef}
    >
      <div className="content">
        {!props.preview && prioritized && (
          <div className="importance">{t("important")}</div>
        )}
        <div className="description">{props.item.description}</div>
        {!props.preview && (
          <div className="inforow">
            <img
              src={personIcon}
              alt={t("itemCard.person-alt")}
              className={`personicon ${!assignee ? "not-assigned" : ""}`}
            />
            <div className="assignee">{assignee}</div>
            <DevelopmentItemStatusRow
              item={props.item}
              tooltipId={DEVELOPMENT_ITEM_TOOLTIP_ID}
            />
          </div>
        )}
      </div>
      <DevelopmentItemMenu
        item={props.item}
        itemListElement={props.itemListElement}
        readOnly={props.readOnly}
        tooltipId={DEVELOPMENT_ITEM_TOOLTIP_ID}
      />
    </DevelopmentItemCardFrame>
  );
}
