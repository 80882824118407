import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./ClubDashboard.scss";
import { RouteComponentProps } from "react-router-dom";
import { ReduxState } from "../../reducers";
import { connect } from "react-redux";
import { fetchHelpLinks } from "../../modules/contentful";
import { LoadingContainer } from "../../components/LoadingContainer";
import { v4 } from "uuid";

type HelpLinksPageProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface HelpLinksPageState {
  helpLinksLoading: boolean;
}

/*
  Fetch tool links from contentful using contentful-module. Group by topic and map through the groups
 */
class HelpLinksPage extends Component<HelpLinksPageProps, HelpLinksPageState> {
  constructor(props: HelpLinksPageProps) {
    super(props);
    this.state = {
      helpLinksLoading: false,
    };
  }

  async componentDidMount() {
    const { helpLinks } = this.props;
    window.addEventListener("refreshEntries", this.fetchLinks);
    if (!helpLinks) {
      this.fetchLinks();
    }
  }

  fetchLinks = async () => {
    this.setState({ helpLinksLoading: true });
    const response = await this.props.fetchHelpLinks();
    if (response) {
      this.setState({ helpLinksLoading: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("refreshEntries", this.fetchLinks);
  }

  render() {
    const { helpLinks, t } = this.props;
    return (
      <div className="helpLinkspage">
        <h1 className="helptext">{t("helpLinks.header")}</h1>
        <LoadingContainer
          loading={this.state.helpLinksLoading}
          className="contentful-helpLinks"
        >
          {helpLinks &&
            helpLinks.items.map((x) => {
              return (
                <a
                  key={v4()}
                  href={x.fields.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {x.fields.name}
                </a>
              );
            })}
        </LoadingContainer>
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{}>
) => {
  return {
    helpLinks: state.contentful.entries.helpLinks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHelpLinks: () => dispatch(fetchHelpLinks()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(HelpLinksPage as any));
