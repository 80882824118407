import { useCallback, useEffect, useRef, useState } from "react";
import filterIcon from "../../images/filter.svg";
import { useTranslation } from "react-i18next";
import "./DevelopmentItemFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import {
  ArraySelectionCheckboxGroup,
  ArraySelectionCheckboxOption,
} from "./ArraySelectionCheckboxGroup";
import {
  DevelopmentItemPriority,
  DevelopmentItemStatus,
  PathType,
} from "../../model";
import {
  CLEAR_DEVELOPMENT_ITEM_FILTERS_STATE,
  DevelopmentItem,
  MAX_YEARS,
  SET_DEVELOPMENT_ITEM_FILTER_STATE,
} from "../../modules/development";
import {
  AuditMetaInfo,
  findPartKeyOfSection,
} from "../../modules/auditmetainfo";
import Select from "react-select";
import { Button, Checkbox } from "react-bootstrap";

interface FilterCriteriaProps {
  title: string;
  itemProperty: keyof DevelopmentItem;
  options: ArraySelectionCheckboxOption[];
  transform?(value: any): any;
}

function FilterCriteria(props: FilterCriteriaProps) {
  const dispatch = useDispatch();
  const itemFilters = useSelector(
    (state: ReduxState) => state.development.view.itemFilters
  );
  const filter = itemFilters
    ? itemFilters.find((filter) => filter.itemProperty === props.itemProperty)
    : undefined;

  return (
    <div>
      <h3>{props.title}</h3>
      <ArraySelectionCheckboxGroup
        disabled={false}
        options={props.options}
        values={filter ? filter.value : []}
        onValueChange={(values) =>
          dispatch(
            SET_DEVELOPMENT_ITEM_FILTER_STATE({
              itemProperty: props.itemProperty,
              value: values,
              transform: props.transform,
            })
          )
        }
      />
    </div>
  );
}

interface DevelopmentItemFilterProps {
  auditMetaInfo: AuditMetaInfo;
  showTopSports: boolean;
  enableYearSelection?: boolean;
}

export function DevelopmentItemFilter(props: DevelopmentItemFilterProps) {
  const { t } = useTranslation("clubDevelopment");
  const itemFilters = useSelector(
    (state: ReduxState) => state.development.view.itemFilters
  );
  const dispatch = useDispatch();
  const yearFilter = itemFilters
    ? itemFilters.find((filter) => filter.itemProperty === "year")
    : undefined;
  const hideLockedFilter = itemFilters
    ? itemFilters.find((filter) => filter.itemProperty === "locked")
    : undefined;

  const [modalPosition, setModalPosition] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const showModal =
    modalPosition.x !== undefined && modalPosition.y !== undefined;

  const updateModalPosition = useCallback(() => {
    if (buttonRef.current && modalRef.current) {
      setModalPosition({
        x:
          buttonRef.current.offsetLeft +
          buttonRef.current.offsetWidth -
          modalRef.current.offsetWidth +
          20,
        y: buttonRef.current.offsetTop + buttonRef.current.offsetHeight + 5,
      });
    }
  }, [setModalPosition]);

  const setShowModal = useCallback(
    (show: boolean) =>
      show
        ? updateModalPosition()
        : setModalPosition({ x: undefined, y: undefined }),
    [updateModalPosition, setModalPosition]
  );

  const getYearOptions = () => {
    const thisYear = new Date().getFullYear();
    return Array(MAX_YEARS)
      .fill(0)
      .map((_, i) => {
        const yearString = (thisYear + i).toString();
        return {
          value: yearString,
          label: yearString,
        };
      });
  };

  const findTopLevelParent = (el: Element) => {
    let parent = el.parentElement;
    if (!parent) {
      return el;
    }
    while (parent.parentElement) {
      parent = parent.parentElement;
    }
    return parent;
  };

  useEffect(() => {
    const hideModalOnOutsideClick = (event) => {
      const modalElement = modalRef.current;
      const buttonElement = buttonRef.current;
      if (
        modalElement &&
        !modalElement.contains(event.target) &&
        buttonElement &&
        !buttonElement.contains(event.target)
      ) {
        // need to exclude select box clicks manually
        const topLevelParent = findTopLevelParent(event.target);
        if (
          !topLevelParent ||
          !topLevelParent.classList.contains("Select-menu-outer")
        ) {
          setShowModal(false);
        }
      }
    };

    const updateModalPositionIfShown = () => showModal && updateModalPosition();

    document.addEventListener("mousedown", hideModalOnOutsideClick);
    window.addEventListener("resize", updateModalPositionIfShown);
    return () => {
      document.removeEventListener("mousedown", hideModalOnOutsideClick);
      window.removeEventListener("resize", updateModalPositionIfShown);
    };
  }, [showModal, setShowModal, updateModalPosition]);

  return (
    <div>
      <div
        ref={modalRef}
        className="filter-modal"
        style={{
          visibility: showModal ? undefined : "hidden",
          willChange: "filter", // without this, Safari will not update the drop shadow properly when visibility changes
          position: "absolute",
          left: modalPosition.x,
          top: modalPosition.y,
        }}
      >
        <h2>{t("filterModal.title")}</h2>
        <div className="subtitle">{t("filterModal.subtitle")}</div>
        <hr />
        <FilterCriteria
          title={t("filterModal.pathType")}
          itemProperty="pathType"
          options={
            props.showTopSports
              ? [
                  { label: t("common:paths.YOUTH"), value: PathType.YOUTH },
                  { label: t("common:paths.ADULTS"), value: PathType.ADULTS },
                  {
                    label: t("common:paths.TOP_SPORTS"),
                    value: PathType.TOP_SPORTS,
                  },
                ]
              : [
                  { label: t("common:paths.YOUTH"), value: PathType.YOUTH },
                  { label: t("common:paths.ADULTS"), value: PathType.ADULTS },
                ]
          }
        />
        <hr />
        <FilterCriteria
          title={t("filterModal.part")}
          itemProperty="theme"
          options={props.auditMetaInfo.parts.map((part) => ({
            label: t(`forms:${part.key}.title`),
            value: part.key,
          }))}
          transform={(value) =>
            findPartKeyOfSection(props.auditMetaInfo, value)
          }
        />
        <hr />
        <FilterCriteria
          title={t("filterModal.status")}
          itemProperty="status"
          options={[
            { label: t("itemPropsForm.undefined"), value: "" },
            {
              label: t("itemStatus.notStarted"),
              value: DevelopmentItemStatus.NOT_STARTED,
            },
            {
              label: t("itemStatus.ongoing"),
              value: DevelopmentItemStatus.ONGOING,
            },
            {
              label: t("itemStatus.ready"),
              value: DevelopmentItemStatus.READY,
            },
          ]}
          transform={(value) => (!value ? "" : value)}
        />
        <hr />
        <FilterCriteria
          title={t("filterModal.priority")}
          itemProperty="priority"
          options={[
            { label: t("itemPropsForm.undefined"), value: "" },
            {
              label: t("important"),
              value: DevelopmentItemPriority.IMPORTANT.toString(),
            },
          ]}
          transform={(value) => (!value ? "" : value.toString())}
        />
        {!!props.enableYearSelection && (
          <div>
            <hr />
            <h3>{t("filterModal.year")}</h3>
            <Select
              options={[
                {
                  label: t("filterModal.notFiltered"),
                  value: null as string | null,
                },
              ]
                .concat(getYearOptions())
                .concat([
                  { label: t("filterModal.yearUndefined"), value: "UNDEFINED" }, // empty string would be transformed to null
                ])}
              placeholder={t("filterModal.notFiltered")}
              simpleValue={true}
              clearable={false}
              searchable={false}
              value={yearFilter ? yearFilter.value : null}
              onChange={(value) =>
                dispatch(
                  SET_DEVELOPMENT_ITEM_FILTER_STATE({
                    itemProperty: "year",
                    value: value,
                    transform: (value) =>
                      !value ? "UNDEFINED" : value.toString(),
                  })
                )
              }
            />
          </div>
        )}
        <hr />
        <Checkbox
          className="hidelocked-checkbox"
          checked={hideLockedFilter ? hideLockedFilter.value : false}
          onChange={(event: any) =>
            dispatch(
              SET_DEVELOPMENT_ITEM_FILTER_STATE({
                itemProperty: "locked",
                value: event.target.checked ? true : null,
                transform: (value) => !value,
              })
            )
          }
        >
          {t("filterModal.hideLocked")}
        </Checkbox>
        <Button
          className="btn-secondary-blue close-button"
          onClick={() => setShowModal(false)}
        >
          {t("filterModal.filter")}
        </Button>
        <button
          className="btn-transparent clear-button"
          onClick={() => dispatch(CLEAR_DEVELOPMENT_ITEM_FILTERS_STATE())}
        >
          {t("filterModal.clear")}
        </button>
      </div>
      <button
        ref={buttonRef}
        className="btn-transparent filter-button"
        onClick={() => setShowModal(!showModal)}
      >
        <div className="filter-icon">
          <img src={filterIcon} alt={t("filter-alt")} />
          {itemFilters.length > 0 && (
            <div className="number-overlay">{itemFilters.length}</div>
          )}
        </div>
        {t("filter")}
      </button>
    </div>
  );
}
