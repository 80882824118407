import { Component } from "react";
import "./PathStartModal.scss";
import { FormGroup, Modal, ControlLabel } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { ClubEnrollment } from "../../model";
import { connect } from "react-redux";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

import { format as fnsFormat, parse as fnsParse } from "date-fns";

import { ReduxState } from "../../reducers";
import {
  SET_PATH_START_MODAL_DATA,
  submitEnrollment,
} from "../../modules/path";

type PathStartModalProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class PathStartModal extends Component<PathStartModalProps, any> {
  constructor(props: PathStartModalProps) {
    super(props);
    this.state = {
      open: false,
      dateInput: "",
      emailInput: "",
    };
  }

  handleEmailInput = (event: any) => {
    this.setState({
      emailInput: event.target.value,
    });
  };
  handleDateInput = (date: any) => {
    this.setState({
      dateInput: date,
    });
  };

  handleStart = () => {
    const { modalData } = this.props;
    const dataToSend = {
      clubId: modalData.orgId,
      decisionTimestamp: this.state.dateInput
        ? fnsFormat(this.state.dateInput, "YYYY-MM-DD")
        : fnsFormat(new Date(), "YYYY-MM-DD"),
      qualityPath:
        modalData && modalData.path ? modalData.path.type : undefined,
    };
    this.props.onSubmitStart(dataToSend);
    this.props.onCloseModal();
  };

  parseDate = (str, format, locale) => {
    const parsed = fnsParse(fnsFormat(str, format, { locale }));
    if ((DayPicker.DateUtils as any).isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  formatDate = (date, format, locale) => {
    return fnsFormat(date, format, { locale });
  };

  render() {
    const { modalData, t } = this.props;
    const FORMAT = "DD.MM.YYYY";

    return (
      <div>
        <Modal
          show={modalData.show}
          onHide={this.props.onCloseModal}
          dialogClassName="pathstart-modal"
        >
          <Modal.Body>
            <h1>{t("modal.pathStart.title")}</h1>
            <p>{t("modal.pathStart.info1")}</p>
            <p>{t("modal.pathStart.info2")}</p>
            <div className="pathstartfield">
              <form>
                <FormGroup>
                  <ControlLabel>{t("path.boardDecision")}</ControlLabel>
                  <DayPickerInput
                    formatDate={this.formatDate}
                    format={FORMAT}
                    parseDate={this.parseDate}
                    placeholder={`${fnsFormat(new Date(), FORMAT)}`}
                    onDayChange={this.handleDateInput}
                  />
                </FormGroup>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <hr />
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={this.handleStart}
              >
                {t("path.pathStart")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={this.props.onCloseModal}
              >
                {t("path.modalCancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const modalTranslated = withTranslation("forms")(PathStartModal);

const mapStateToProps = (state: ReduxState) => {
  return {
    modalData: state.path.pathStartModalData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () =>
    dispatch(
      SET_PATH_START_MODAL_DATA({
        show: false,
        path: undefined,
        orgId: undefined,
      })
    ),
  onSubmitStart: (data: ClubEnrollment) => dispatch(submitEnrollment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(modalTranslated);
