import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AttachmentsTable } from "../../components/Tables/AttachmentsTable";
import Fuse from "fuse.js";
import "./Attachments.scss";
import { AttachmentModal } from "../../components/AttachmentModal/AttachmentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_ATTACHMENT_MODAL_DATA,
  fetchOrgAttachments,
} from "../../modules/attachment";
import { ReduxState } from "../../reducers";
import { useParams } from "react-router";

const options = {
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};

interface Props {}

export function ClubAttachments(props: Props) {
  const { t } = useTranslation("common");
  const [search, setSearch] = useState("");
  const { orgId } = useParams<any>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (orgId) {
      dispatch(fetchOrgAttachments(orgId));
    }
  }, [orgId, dispatch]);
  const list = useSelector(
    (state: ReduxState) => state.attachment.orgAttachments
  );
  const openModal = () =>
    dispatch(
      SET_ATTACHMENT_MODAL_DATA({ modalOpen: true, showLinkTable: false })
    );

  const attachmentsFuse = new Fuse(list, options);

  return (
    <div className="attachments-page">
      <div className="attachments-container">
        <div className="file-search">
          <span>{t("attachments.find")}</span>
          <input
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("attachments.find-placeholder")}
          />
        </div>
        <hr />
        <AttachmentsTable
          list={search ? attachmentsFuse.search(search) : list}
          allowEdit={true}
          allowDelete={true}
        />
        <div className="buttons">
          <button
            type="button"
            className="btn btn-secondary btn-secondary-blue"
            onClick={() => openModal()}
          >
            {t("attachments.add-button")}
          </button>
        </div>
      </div>
      <AttachmentModal formLinkMode={false} />
    </div>
  );
}
