import { SICLButtonStyle } from "@suomisport/ui-components/components/SICLBaseButton";
import { SICLButton } from "@suomisport/ui-components/components/SICLButton";
import {
  SICLModal,
  SICLModalContent,
  SICLModalProps,
} from "@suomisport/ui-components/components/SICLModal";
import { useTranslation } from "react-i18next";
import { VSpacer } from "../VSpacer";
import css from "./DeleteQualityPathConfirmationModal.module.scss";

export interface DeleteQualityPathConfirmationModal_Props {
  siclModalProps: Omit<SICLModalProps, "children">;
  clubName: string;
  qualityPathName: string;
  onConfirm: () => void;
}

export const DeleteQualityPathConfirmationModal = (
  props: DeleteQualityPathConfirmationModal_Props
) => {
  const { t } = useTranslation("common");

  return (
    <SICLModal {...props.siclModalProps}>
      <SICLModalContent title={t("DeleteQualityPathConfirmationModal.title")}>
        <div className={css.container}>
          <div className={css.infoLine}>
            {t("DeleteQualityPathConfirmationModal.club")}:{" "}
            <strong>{props.clubName}</strong>
          </div>
          <div className={css.infoLine}>
            {t("DeleteQualityPathConfirmationModal.qualityPath")}:{" "}
            <strong>{props.qualityPathName}</strong>
          </div>
          <VSpacer gap={50} />
          <div className={css.buttonsContainer}>
            <div className={css.buttonContainer}>
              <SICLButton
                fillParent
                onClick={() => {
                  props.siclModalProps.onRequestClose();
                }}
                label={t("DeleteQualityPathConfirmationModal.cancel")}
                buttonStyle={SICLButtonStyle.Cancel}
              />
            </div>
            <div className={css.buttonContainer}>
              <SICLButton
                fillParent
                onClick={() => {
                  props.onConfirm();
                }}
                label={t(
                  "DeleteQualityPathConfirmationModal.deleteQualityPath"
                )}
              />
            </div>
          </div>
        </div>
      </SICLModalContent>
    </SICLModal>
  );
};
