import { ThemeListView } from "./ThemeListView";
import { ReactElement, useRef } from "react";
import "./DevelopmentItemListContainer.scss";
import { DraggableDevelopmentItemCard } from "../../components/DevelopmentItemCard/DraggableDevelopmentItemCard";
import { DevelopmentItem } from "../../modules/development";
import { useDrop } from "react-dnd";
import { DragTypes } from "./DragTypes";
import { useDispatch, useSelector } from "react-redux";
import { PathType } from "../../model";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { AuditMetaInfo } from "../../modules/auditmetainfo";
import { SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE } from "../../modules/developmentModals";
import { ReduxState } from "../../reducers";
import { OrganizationCapabilityType } from "../../modules/organization";

interface DevelopmentItemListContainerProps {
  developmentItems: DevelopmentItem[];
  auditMetaInfo: AuditMetaInfo;
  readOnly: boolean;
  onSetItemYear(id: number, year: number | null): void;
}

const createItemListThemeComponent = (
  developmentItems: DevelopmentItem[],
  itemListElement: HTMLElement | undefined,
  openNewDevelopmentItemModal: (
    theme: string | null,
    showTopSports: boolean
  ) => void,
  t: TFunction,
  readOnly: boolean
) => (theme: string | null, showTopSports: boolean): ReactElement | null => {
  const cardsForTheme = developmentItems
    .filter(
      (item) => ((!item.theme && !theme) || item.theme === theme) && !item.year
    )
    .map((item, index) => (
      <DraggableDevelopmentItemCard
        item={item}
        preview={true}
        itemListElement={itemListElement}
        key={item.id || -index} // locked items don't have ids
        readOnly={readOnly}
      />
    ));

  return cardsForTheme.length > 0 ? (
    <div className="development-item-list-theme">
      {cardsForTheme}
      <div className="addnewitem">
        <button
          className="btn-transparent newitembtn"
          onClick={() => openNewDevelopmentItemModal(theme, showTopSports)}
          disabled={readOnly}
        >
          + {t("addItem")}
        </button>
      </div>
    </div>
  ) : null;
};

export function DevelopmentItemListContainer(
  props: DevelopmentItemListContainerProps
) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragTypes.DEVELOPMENT_ITEM_CARD,
    canDrop: (item: any) => !!item.developmentItem.year,
    drop: (item: any) => props.onSetItemYear(item.developmentItem.id, null),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const dispatch = useDispatch();

  const federationId = props.auditMetaInfo.federationId;

  const federationCapabilities = useSelector(
    (state: ReduxState) => state.organization.capabilities[federationId]
  );

  const showTopSports = federationCapabilities?.some((capability) => {
    return (
      capability.capabilityType ===
        OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
      capability.value === "true"
    );
  });

  const openNewDevelopmentItemModal = (
    theme: string | null,
    showTopSports: boolean
  ) =>
    dispatch(
      SET_DEVELOPMENT_ITEM_PROPS_MODAL_STATE({
        modalIsOpen: true,
        item: {
          description: "",
          pathType: showTopSports ? PathType.ALL : PathType.YOUTH_AND_ADULTS,
          theme: theme ? theme : undefined,
        },
      })
    );

  const { t } = useTranslation("clubDevelopment");

  const itemListRef = useRef<HTMLElement>(null);

  return (
    <div className="development-item-list-container">
      <div className="fade" />
      <div
        className={`development-item-list ${
          canDrop
            ? isOver
              ? "development-item-list-dragover"
              : "development-item-list-droppable"
            : ""
        }`}
        ref={(instance) => {
          drop(instance);
          (itemListRef as any).current = instance;
        }}
      >
        <h3>{t("developmentItems")}</h3>
        <ThemeListView
          auditMetaInfo={props.auditMetaInfo}
          generateContent={createItemListThemeComponent(
            props.developmentItems,
            itemListRef.current || undefined,
            openNewDevelopmentItemModal,
            t,
            props.readOnly
          )}
          placeholder={
            <div className="development-item-list-theme">
              <div className="addnewitem">
                <button
                  className="btn-transparent newitembtn"
                  onClick={() =>
                    openNewDevelopmentItemModal(null, showTopSports)
                  }
                  disabled={props.readOnly}
                >
                  + {t("addItem")}
                </button>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
