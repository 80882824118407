import "./ClubDevelopmentIntroModal.scss";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { CLOSE_CLUB_DEVELOPMENT_INTRO_MODAL } from "../../modules/developmentModals";
import { useTranslation } from "react-i18next";
import { translateWithMarkdown } from "../../util/translateWithMarkdown";
import stepDirectionIcon from "../../images/step-direction.svg";
import stepActionIcon from "../../images/step-action.svg";
import stepResultsIcon from "../../images/step-results.svg";

type ClubDevelopmentIntroModalProps = {
  onClose?: () => void;
};

export function ClubDevelopmentIntroModal(
  props: ClubDevelopmentIntroModalProps
) {
  const modalData = useSelector(
    (state: ReduxState) => state.development.clubDevelopmentIntroModal
  );
  const dispatch = useDispatch();
  const { t } = useTranslation("clubDevelopment");
  const T = translateWithMarkdown(t);

  const closeModal = () => {
    dispatch(CLOSE_CLUB_DEVELOPMENT_INTRO_MODAL());
    if (props.onClose) props.onClose();
  };

  return (
    <div>
      <Modal
        show={modalData.modalIsOpen}
        onHide={closeModal}
        className="centered-modal"
        dialogClassName="club-development-intro-modal"
      >
        <Modal.Body>
          <h1>{t("intro.title")}</h1>
          <div className="intro-desc">{T("intro.description")}</div>
          <hr />
          <h3>{t("intro.preface-title")}</h3>
          <div className="intro-preface">
            {T("intro.preface-body", { markdown: { multiline: true } })}
          </div>
          <div className="step">
            <img src={stepDirectionIcon} alt={t("mainView.step1-alt")} />
            <div>
              <h3>{t("intro.step1-title")}</h3>
              {T("intro.step1-desc")}
            </div>
          </div>
          <div className="step">
            <img src={stepActionIcon} alt={t("mainView.step2-alt")} />
            <div>
              <h3>{t("intro.step2-title")}</h3>
              {T("intro.step2-desc")}
            </div>
          </div>
          <div className="step">
            <img src={stepResultsIcon} alt={t("mainView.step3-alt")} />
            <div>
              <h3>{t("intro.step3-title")}</h3>
              {T("intro.step3-desc")}
            </div>
          </div>
          <div className="intro-postface">{T("intro.postface")}</div>
          <button
            type="button"
            className="btn-secondary-blue close-button"
            onClick={closeModal}
          >
            {t(
              modalData.firstTimeUser ? "intro.first-time-close" : "intro.close"
            )}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
