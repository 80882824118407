import { Tooltip, PieChart, Pie, Cell } from "recharts";
import { map } from "lodash";
import { withTranslation, WithTranslation } from "react-i18next";
import { translatedRatingMap } from "../Forms/V2/model";

export interface IDonutChartProps {
  title: string;
  rawData: any;
  percData: any;
  colors: object;
  order: string[];
  children: any;
  onTitleClick?: any;
}

function colorBrightness(color) {
  const hex = color.replace("#", "");
  const red = parseInt(hex.substr(0, 2), 16);
  const green = parseInt(hex.substr(2, 2), 16);
  const blue = parseInt(hex.substr(4, 2), 16);
  return (red * 299 + green * 587 + blue * 114) / 1000;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props: IDonutChartProps) => ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
}) => {
  if (percent < 0.01) {
    return undefined;
  }
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const bgColor = props.colors[name];

  return (
    <text
      style={{ pointerEvents: "none" }}
      x={x - 12}
      y={y}
      fill={colorBrightness(bgColor) > 155 ? "black" : "white"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function DonutChartComponent(props: IDonutChartProps & WithTranslation) {
  const empty: boolean =
    !props.rawData || Object.keys(props.rawData).length === 0;
  const piedata: any[] = empty
    ? [{ name: "_EMPTY", value: 1 }]
    : map(props.percData, (value: number, key: string) => {
        return {
          name: key,
          value: value,
        };
      }).sort((entry1, entry2) =>
        props.order.indexOf(entry1.name) < props.order.indexOf(entry2.name)
          ? -1
          : 1
      );
  return (
    <div className="donutchart">
      <PieChart width={200} height={200} className="donut">
        <Tooltip
          cursor={{ fill: "none" }}
          animationDuration={300}
          animationEasing="linear"
          {...(empty && {
            content: () => (
              <span className="emptytooltip">
                {props.t("auditGraphs.empty")}
              </span>
            ),
          })}
          formatter={(value, name) => {
            if (empty || typeof value !== "number") {
              return undefined;
            }
            return [
              `${(props.rawData[name] * 100).toFixed(1)}%`,
              translatedRatingMap(props.t)[name],
            ];
          }}
        />
        <Pie
          isAnimationActive={false}
          paddingAngle={1}
          data={piedata}
          labelLine={false}
          outerRadius="100%"
          fill="#8884d8"
          dataKey="value"
          innerRadius="40%"
          label={!empty ? (renderCustomizedLabel(props) as any) : undefined}
        >
          {piedata.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={props.colors[entry.name]} />
          ))}
        </Pie>
      </PieChart>
      {props.children}
      <span
        className={
          props.onTitleClick ? "donuttitle donuttitlelink" : "donuttitle"
        }
        onClick={() => (props.onTitleClick ? props.onTitleClick() : null)}
      >
        {props.title}
      </span>
    </div>
  );
}

export const DonutChart = withTranslation("forms")(DonutChartComponent);
