import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../reducers";
import { linkAttachments } from "../../modules/attachment";
import { useParams } from "react-router";
import { PartStatus } from "../../model";
import { updateAuditRequestPart } from "../../modules/auditRequest";
import { AttachmentModal } from "./AttachmentModal";
import Alert from "react-s-alert";
import { useTranslation } from "react-i18next";

export function AttachmentAuditModal() {
  const { modalData, linkedAttachments } = useSelector((state: ReduxState) => ({
    modalData: state.attachment.modalData,
    linkedAttachments: state.attachment.modalData.fieldName
      ? state.attachment.partAttachments[state.attachment.modalData.fieldName]
      : null,
  }));

  const dispatch = useDispatch();
  const { t } = useTranslation("common");

  const { fieldName } = modalData;
  const { requestId, part } = useParams<any>();

  const linkAttachmentsToField = async (attachmentIds: number[]) => {
    const { enrollment } = modalData;

    if (!(fieldName && requestId && part)) {
      throw new Error("MISSING_ATTACHMENT_DATA");
    }

    await setPartStateToDraft();
    // need to give the whole updated list to the API
    await dispatch(
      linkAttachments(
        enrollment!.id!,
        Number(requestId),
        part,
        fieldName,
        attachmentIds
      )
    );

    Alert.info(t("common:apiAlerts.linkAttachment"));
  };

  const setPartStateToDraft = async () => {
    const { auditRequest, enrollment } = modalData;
    const partObject = auditRequest
      ? auditRequest.parts.find((x) => x.key === part)
      : null;
    if (
      enrollment &&
      auditRequest &&
      part &&
      partObject &&
      partObject.state !== "DRAFT"
    ) {
      await dispatch(
        updateAuditRequestPart(
          enrollment.id!,
          auditRequest.id.toString(),
          part,
          PartStatus.DRAFT,
          true
        )
      );
    }
  };

  return (
    <AttachmentModal
      linkedAttachments={linkedAttachments}
      formLinkMode={true}
      linkAttachmentsToField={linkAttachmentsToField}
    />
  );
}
