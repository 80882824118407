import { Fragment, Component } from "react";
import { Field } from "redux-form";
import { ExpandingContentRowHeader } from "../../../Headers/ExpandingContentRowHeader";
import SelectField from "../../Fields/SelectField";
import { PartFormProps } from "../../../../model";
import { translatedOptions, translatedRatingMap } from "../model";
import { CommentMode } from "../../../../modules/comment";
import TextAreaField from "../../Fields/TextAreaField";
import AuditField from "../../Fields/AuditField";
import { get, find } from "lodash";
import ToolLinks from "../../Components/ToolLinks";
import { WithTranslation } from "react-i18next";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class YouthManagementPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const { t, commentEdit, orgId, formData, tools, readOnlyMode } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    return (
      <Fragment>
        <ExpandingContentRowHeader
          title={t("shared.management.leadership.formHeader")}
          open={true}
        >
          <div className="form-section">
            <Field
              options={options}
              name="leadershipValues"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.leadership.values.placeholder")}
              label={t("shared.management.leadership.values.label")}
              helpText={t("shared.management.leadership.values.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipValues"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.values.label")}
              fieldValue={ratingMap[get(formData, "leadershipValues")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipVision"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.leadership.vision.placeholder")}
              label={t("shared.management.leadership.vision.label")}
              helpText={t("shared.management.leadership.vision.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipVision"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.vision.label")}
              fieldValue={ratingMap[get(formData, "leadershipVision")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipEquality"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.equality.placeholder"
              )}
              label={t("shared.management.leadership.equality.label")}
              helpText={t("shared.management.leadership.equality.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipEquality"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.equality.label")}
              fieldValue={ratingMap[get(formData, "leadershipEquality")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipYouth"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.leadership.youth.placeholder")}
              label={t("shared.management.leadership.youth.label")}
              helpText={t("shared.management.leadership.youth.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipYouth"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.youth.label")}
              fieldValue={ratingMap[get(formData, "leadershipYouth")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipImprovement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.improvement.placeholder"
              )}
              label={t("shared.management.leadership.improvement.label")}
              helpText={t("shared.management.leadership.improvement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipImprovement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.leadership.improvement.label"
              )}
              fieldValue={ratingMap[get(formData, "leadershipImprovement")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipAdminimprovement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.adminimprovement.placeholder"
              )}
              label={t("shared.management.leadership.adminimprovement.label")}
              helpText={t("shared.management.leadership.adminimprovement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipAdminimprovement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.leadership.adminimprovement.label"
              )}
              fieldValue={
                ratingMap[get(formData, "leadershipAdminimprovement")]
              }
            />
            <hr />
            <Field
              options={options}
              name="leadershipEnvironment"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.environment.placeholder"
              )}
              label={t("shared.management.leadership.environment.label")}
              helpText={t("shared.management.leadership.environment.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipEnvironment"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.leadership.environment.label"
              )}
              fieldValue={ratingMap[get(formData, "leadershipEnvironment")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipCooperation"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.cooperation.placeholder"
              )}
              label={t("shared.management.leadership.cooperation.label")}
              helpText={t("shared.management.leadership.cooperation.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipCooperation"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.leadership.cooperation.label"
              )}
              fieldValue={ratingMap[get(formData, "leadershipCooperation")]}
            />
            <hr />
            <Field
              options={options}
              name="leadershipChanges"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.leadership.changes.placeholder"
              )}
              label={t("shared.management.leadership.changes.label")}
              helpText={t("shared.management.leadership.changes.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipChanges"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.changes.label")}
              fieldValue={ratingMap[get(formData, "leadershipChanges")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.leadership.positive")}
            </div>
            <Field
              name="leadershipPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.leadership.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.positive")}
              fieldValue={ratingMap[get(formData, "leadershipPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.leadership.negative")}
            </div>
            <Field
              name="leadershipNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.leadership.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"leadershipNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.leadership.negative")}
              fieldValue={ratingMap[get(formData, "leadershipNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthManagementLeadership"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.management.management.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="managementRules"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.management.rules.placeholder")}
              label={t("shared.management.management.rules.label")}
              helpText={t("shared.management.management.rules.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementRules"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.rules.label")}
              fieldValue={ratingMap[get(formData, "managementRules")]}
            />
            <hr />
            <Field
              options={options}
              name="managementFairplay"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.fairplay.placeholder"
              )}
              label={t("shared.management.management.fairplay.label")}
              helpText={t("shared.management.management.fairplay.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementFairplay"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.fairplay.label")}
              fieldValue={ratingMap[get(formData, "managementFairplay")]}
            />
            <hr />
            <Field
              options={options}
              name="managementGuidebook"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.guidebook.placeholder"
              )}
              label={t("shared.management.management.guidebook.label")}
              helpText={t("shared.management.management.guidebook.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementGuidebook"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.management.guidebook.label"
              )}
              fieldValue={ratingMap[get(formData, "managementGuidebook")]}
            />
            <hr />
            <Field
              options={options}
              name="managementYear"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.management.year.placeholder")}
              label={t("shared.management.management.year.label")}
              helpText={t("shared.management.management.year.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementYear"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.year.label")}
              fieldValue={ratingMap[get(formData, "managementYear")]}
            />
            <hr />
            <Field
              options={options}
              name="managementFinancialguide"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.financialguide.placeholder"
              )}
              label={t("shared.management.management.financialguide.label")}
              helpText={t("shared.management.management.financialguide.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementFinancialguide"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.management.financialguide.label"
              )}
              fieldValue={ratingMap[get(formData, "managementFinancialguide")]}
            />
            <hr />
            <Field
              options={options}
              name="managementFinancial"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.financial.placeholder"
              )}
              label={t("shared.management.management.financial.label")}
              helpText={t("shared.management.management.financial.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementFinancial"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.management.financial.label"
              )}
              fieldValue={ratingMap[get(formData, "managementFinancial")]}
            />
            <hr />
            <Field
              options={options}
              name="managementResponsibilities"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.responsibilities.placeholder"
              )}
              label={t("shared.management.management.responsibilities.label")}
              helpText={t("shared.management.management.responsibilities.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementResponsibilities"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.management.responsibilities.label"
              )}
              fieldValue={
                ratingMap[get(formData, "managementResponsibilities")]
              }
            />
            <hr />
            <Field
              options={options}
              name="managementEmployer"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.employer.placeholder"
              )}
              label={t("shared.management.management.employer.label")}
              helpText={t("shared.management.management.employer.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementEmployer"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.employer.label")}
              fieldValue={ratingMap[get(formData, "managementEmployer")]}
            />
            <hr />
            <Field
              options={options}
              name="managementStatement"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.management.statement.placeholder"
              )}
              label={t("shared.management.management.statement.label")}
              helpText={t("shared.management.management.statement.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementStatement"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.management.statement.label"
              )}
              fieldValue={ratingMap[get(formData, "managementStatement")]}
            />
            <hr />
            <Field
              options={options}
              name="managementCrime"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t("shared.management.management.crime.placeholder")}
              label={t("shared.management.management.crime.label")}
              helpText={t("shared.management.management.crime.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"managementCrime"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.crime.label")}
              fieldValue={ratingMap[get(formData, "managementCrime")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.management.positive")}
            </div>
            <Field
              name="managementPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.management.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"managementPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.positive")}
              fieldValue={ratingMap[get(formData, "managementPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.management.negative")}
            </div>
            <Field
              name="managementNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.management.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"managementNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.management.negative")}
              fieldValue={ratingMap[get(formData, "managementNegative")]}
            />
          </div>
          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthManagementManagement"
            )}
          />
        </ExpandingContentRowHeader>
        <ExpandingContentRowHeader
          title={t("shared.management.communication.formHeader")}
        >
          <div className="form-section">
            <Field
              options={options}
              name="communicationVaried"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.varied.placeholder"
              )}
              label={t("shared.management.communication.varied.label")}
              helpText={t("shared.management.communication.varied.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationVaried"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.communication.varied.label"
              )}
              fieldValue={ratingMap[get(formData, "communicationVaried")]}
            />
            <hr />
            <Field
              options={options}
              name="communicationTransparent"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.transparent.placeholder"
              )}
              label={t("shared.management.communication.transparent.label")}
              helpText={t("shared.management.communication.transparent.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationTransparent"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.communication.transparent.label"
              )}
              fieldValue={ratingMap[get(formData, "communicationTransparent")]}
            />
            <hr />
            <Field
              options={options}
              name="communicationRoles"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.roles.placeholder"
              )}
              label={t("shared.management.communication.roles.label")}
              helpText={t("shared.management.communication.roles.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationRoles"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.communication.roles.label")}
              fieldValue={ratingMap[get(formData, "communicationRoles")]}
            />
            <hr />
            <Field
              options={options}
              name="communicationWebsite"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.website.placeholder"
              )}
              label={t("shared.management.communication.website.label")}
              helpText={t("shared.management.communication.website.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationWebsite"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.communication.website.label"
              )}
              fieldValue={ratingMap[get(formData, "communicationWebsite")]}
            />
            <hr />
            <Field
              options={options}
              name="communicationAward"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.award.placeholder"
              )}
              label={t("shared.management.communication.award.label")}
              helpText={t("shared.management.communication.award.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationAward"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.communication.award.label")}
              fieldValue={ratingMap[get(formData, "communicationAward")]}
            />
            <hr />
            <Field
              options={options}
              name="communicationMarketing"
              className={"pathdropdown"}
              component={SelectField}
              placeholder={t(
                "shared.management.communication.marketing.placeholder"
              )}
              label={t("shared.management.communication.marketing.label")}
              helpText={t("shared.management.communication.marketing.help")}
              disabled={readOnly}
              showAddDesc={true}
              descriptionEdit={!commentEdit}
            />
            <AuditField
              ratingMap={ratingMap}
              edit={commentEdit}
              type={type}
              fieldName={"communicationMarketing"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t(
                "shared.management.communication.marketing.label"
              )}
              fieldValue={ratingMap[get(formData, "communicationMarketing")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.communication.positive")}
            </div>
            <Field
              name="communicationPositive"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.communication.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"communicationPositive"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.communication.positive")}
              fieldValue={ratingMap[get(formData, "communicationPositive")]}
            />
            <hr />
            <div className="widefieldlabel">
              {t("shared.management.communication.negative")}
            </div>
            <Field
              name="communicationNegative"
              formCtrlClassName="wideformfield"
              component={TextAreaField}
              placeholder={t("shared.management.communication.placeholder")}
              disabled={readOnly}
              maxlength={2000}
            />
            <AuditField
              ratingMap={ratingMap}
              hideAudit={true}
              edit={commentEdit}
              type={type}
              fieldName={"communicationNegative"}
              orgId={orgId}
              commentMode={CommentMode.Field}
              fieldTranslated={t("shared.management.communication.negative")}
              fieldValue={ratingMap[get(formData, "communicationNegative")]}
            />
          </div>

          <ToolLinks
            tools={find(
              partThemes,
              (x) => x.fields.type === "youthManagementCommunication"
            )}
          />
        </ExpandingContentRowHeader>
      </Fragment>
    );
  }
}

export default YouthManagementPart as any;
