import { Fragment, Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./homePublic.scss";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { ReduxState } from "../../reducers";
import { fetchKPI } from "../../modules/contentful";
import MainRouteViewWrapper from "../MainRouteViewWrapper";
import homebenefit1 from "../../images/frontpage/ikoni_yhteistyo.svg";
import homebenefit2 from "../../images/frontpage/ikoni_imago.svg";
import homebenefit3 from "../../images/frontpage/ikoni_tarkastelu.svg";
import homebenefit4 from "../../images/frontpage/ikoni_kehitys.svg";
import { LoadingContainer } from "../../components/LoadingContainer";

const homePageLinks = {
  dev: {
    onboard: process.env.REACT_APP_DEV_ONBOARDING,
    suomisport: process.env.REACT_APP_DEV_SUOMISPORT,
  },
  staging: {
    onboard: process.env.REACT_APP_STAGING_ONBOARDING,
    suomisport: process.env.REACT_APP_STAGING_SUOMISPORT,
  },
  prod: {
    onboard: process.env.REACT_APP_PROD_ONBOARDING,
    suomisport: process.env.REACT_APP_PROD_SUOMISPORT,
  },
};

type HomePublicProps = WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export interface HomePublicState {
  kpiLoading: boolean;
  showOneSport: number | null;
  joinLinks: any;
}

class HomePublic extends Component<HomePublicProps, HomePublicState> {
  constructor(props: HomePublicProps) {
    super(props);
    this.state = {
      kpiLoading: false,
      showOneSport: null,
      joinLinks: props.environment ? homePageLinks[props.environment] : null,
    };
  }

  componentWillReceiveProps(
    nextProps: Readonly<HomePublicProps>,
    nextContext: any
  ) {
    this.setState({
      joinLinks: nextProps.environment
        ? homePageLinks[nextProps.environment]
        : null,
    });
  }

  async componentDidMount() {
    this.setState({ kpiLoading: true });
    const response = await this.props.fetchKPI();
    if (response) {
      this.setState({ kpiLoading: false });
    }
  }

  toggleShowSport = (id: number) => {
    this.setState({ showOneSport: id });
  };

  render() {
    const { kpi, t } = this.props;
    const kpiToShow = kpi && kpi.items[0] ? kpi.items[0].fields : {};
    return (
      <MainRouteViewWrapper accent={true} pictureText={""} footer={true}>
        <div className="homepublic">
          <h1 className="topic">{t("home.title")}</h1>
          <div className="subtopic">
            <p>{t("home.intro1")}</p>
            <p>{t("home.intro2")}</p>
            <p>
              <b>{t("home.intro3")}</b>
            </p>
          </div>
          <div className="join">
            <h1>{t("home.jointitle")}</h1>
            {this.state.joinLinks && (
              <Fragment>
                <div className="joinbuttons">
                  <a
                    className="btn btn-secondary-blue btn-secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.joinLinks.onboard}
                  >
                    {t("home.joinbuttons.onboarding")}
                  </a>
                </div>
                <div className="instructionscontainer">
                  <div className="subtitle">
                    {t("home.joinbuttons.subtitle")}
                  </div>
                  <ul>
                    <li>{t("home.joinbuttons.step1")}</li>
                    <li>{t("home.joinbuttons.step2")}</li>
                  </ul>
                </div>
              </Fragment>
            )}
          </div>
          <LoadingContainer
            className="kpicontainer"
            loading={this.state.kpiLoading}
          >
            <div className="bigkpi">
              <div className="boxwrapper">
                <div className="number box1">
                  <div className="amount">{kpiToShow.tahtiseura}</div>
                  <div className="text">{t("home.kpitotal")}</div>
                </div>
                <div className="number box2">
                  <div className="amount">{kpiToShow.children}</div>
                  <div className="text">{t("home.kpiyouth")}</div>
                </div>
                <div className="number box3">
                  <div className="amount">{kpiToShow.adults}</div>
                  <div className="text">{t("home.kpiadults")}</div>
                </div>
              </div>
            </div>
            <div className="smallkpi">
              <div className="box youthbox">
                <div className="title">{t("home.smallkpiyouth")}</div>
                <div className="number">{kpiToShow.childrenclubs}</div>
              </div>
              <div className="box adultsbox">
                <div className="title">{t("home.smallkpiadults")}</div>
                <div className="number">{kpiToShow.adultsclubs}</div>
              </div>
              <div className="box probox">
                <div className="title">{t("home.smallkpipro")}</div>
                <div className="number">{kpiToShow.profclubs}</div>
              </div>
              <div className="box">
                <div className="title">{t("home.smallkpifeds")}</div>
                <div className="number">{kpiToShow.federations}</div>
              </div>
            </div>
          </LoadingContainer>
          <div className="benefits">
            <h1 className="topic">{t("home.benefitTopic")}</h1>
            <div className="boxes">
              <div>
                <img src={homebenefit4} alt="" />
                <div className="text">{t("home.benefit1")}</div>
              </div>
              <div>
                <img src={homebenefit3} alt="" />
                <div className="text">{t("home.benefit2")}</div>
              </div>
              <div>
                <img src={homebenefit1} alt="" />
                <div className="text">{t("home.benefit3")}</div>
              </div>
              <div>
                <img src={homebenefit2} alt="" />
                <div className="text">{t("home.benefit4")}</div>
              </div>
            </div>
          </div>
        </div>
      </MainRouteViewWrapper>
    );
  }
}

const homeTranslated = withTranslation("common")(HomePublic);

const mapStateToProps = (
  state: ReduxState,
  ownProps: RouteComponentProps<{}>
) => {
  return {
    user: state.account.user,
    kpi: state.contentful.entries.kpi,
    environment: state.config.environment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchKPI: () => dispatch(fetchKPI()),
});

const HomeConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(homeTranslated);

export default withRouter<RouteComponentProps<{}>, any>(HomeConnected);
