import { useDragLayer } from "react-dnd";
import { DevelopmentItemCard } from "../../components/DevelopmentItemCard/DevelopmentItemCard";

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px) rotate(5deg)`;
  return {
    width: 300,
    transform,
    WebkitTransform: transform,
  };
}

export function DevelopmentItemCardDragLayer() {
  const { item, initialOffset, currentOffset, isDragging } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );
  if (!isDragging || !item || !item.developmentItem) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 10000,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <DevelopmentItemCard item={item.developmentItem} readOnly={false} />
      </div>
    </div>
  );
}
