import { useDrop } from "react-dnd";
import { DragTypes } from "./DragTypes";
import "./YearTab.scss";
import { useTranslation } from "react-i18next";

interface YearTabProps {
  year: number;
  numItems: number;
  selected: boolean;
  forceCanDrop?: boolean;
  forceDragIsOver?: boolean;
  onYearSelected(year: number): void;
  onSetItemYear(id: number, year: number | null): void;
}

export const YearTab = (props: YearTabProps) => {
  const {
    year,
    numItems,
    selected,
    onYearSelected,
    onSetItemYear,
    forceCanDrop,
    forceDragIsOver,
  } = props;

  const { t } = useTranslation("clubDevelopment");

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragTypes.DEVELOPMENT_ITEM_CARD,
    canDrop: (item: any) => item.developmentItem.year !== year && !selected,
    drop: (item: any) => onSetItemYear(item.developmentItem.id, year),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      className={`year-tab ${selected ? "year-tab-selected" : ""} ${
        (!selected && canDrop) || forceCanDrop
          ? (!selected && isOver) || forceDragIsOver
            ? "year-tab-dragover"
            : "year-tab-droppable"
          : ""
      }`}
      ref={drop}
    >
      <div className="background" />
      <button
        className="btn-transparent year-button"
        onClick={() => onYearSelected(year)}
      >
        <span className="year">{year}</span>
        <span className="numitems">
          {numItems}{" "}
          {numItems === 1 ? t("numItems.singular") : t("numItems.plural")}
        </span>
      </button>
    </div>
  );
};
