import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";
import { withTranslation, WithTranslation } from "react-i18next";

export interface IHorizontalBarProps {
  title: string;
  rawData: any;
  percData: any;
  colors: object;
  order: string[];
}

function HorizontalBarComponent(props: IHorizontalBarProps & WithTranslation) {
  const empty: boolean =
    !props.rawData || Object.keys(props.rawData).length === 0;
  let bardata: any;

  // firefox handling sort order differently than e.g. chrome and edge
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

  if (empty) {
    bardata = { _EMPTY: 1 };
  } else {
    bardata = props.percData;
  }

  return (
    <div className="horizontalbar">
      <span className="bartitle">{props.title}</span>
      <BarChart layout="vertical" data={[bardata]} width={200} height={27}>
        <Tooltip
          cursor={{ fill: "none" }}
          itemStyle={{ color: "black" }}
          animationDuration={300}
          animationEasing="linear"
          {...(empty && {
            content: () => (
              <span className="emptytooltip">
                {props.t("auditGraphs.empty")}
              </span>
            ),
          })}
          formatter={(value, name) => {
            if (empty || typeof value !== "number") {
              return undefined;
            }
            return [
              `${(props.rawData[name.toUpperCase()] * 100).toFixed(1)}%`,
              props.t(`auditGraphs.${name}`),
            ];
          }}
          itemSorter={(entry) =>
            (isFirefox ? 1 : -1) * props.order.indexOf(entry.name)
          }
        />
        <XAxis type="number" hide={true} />
        <YAxis type="category" dataKey="none" hide={true} />
        <Bar
          isAnimationActive={false}
          name="excellent"
          dataKey={"EXCELLENT"}
          stackId="a"
          fill={props.colors["EXCELLENT"]}
        />
        <Bar
          isAnimationActive={false}
          name="good"
          dataKey={"GOOD"}
          stackId="a"
          fill={props.colors["GOOD"]}
        />
        <Bar
          isAnimationActive={false}
          name="sufficient"
          dataKey={"SUFFICIENT"}
          stackId="a"
          fill={props.colors["SUFFICIENT"]}
        />
        <Bar
          isAnimationActive={false}
          name="insufficient"
          dataKey={"INSUFFICIENT"}
          stackId="a"
          fill={props.colors["INSUFFICIENT"]}
        />
        <Bar
          isAnimationActive={false}
          name="empty"
          dataKey="_EMPTY"
          stackId="a"
          fill={props.colors["_EMPTY"]}
        />
      </BarChart>
    </div>
  );
}

export const HorizontalBar = withTranslation("forms")(HorizontalBarComponent);
