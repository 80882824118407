import ReactTooltip from "react-tooltip";
import "./DevelopmentItemTooltip.scss";
import { useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { identity } from "lodash";

export type TooltipData = {
  title: string;
  content: string | string[];
};

interface DevelopmentItemTooltipProps {
  id: string;
  itemVisibilityKey?: string;
}

export const DevelopmentItemTooltip = (props: DevelopmentItemTooltipProps) => {
  const key =
    useSelector(
      (state: ReduxState) => state.development.view.itemViewStateKey
    ) + (props.itemVisibilityKey || "");

  return (
    <ReactTooltip
      id={props.id}
      key={key}
      effect="solid"
      backgroundColor="white"
      textColor="#4A4A4A"
      borderColor="#B2B2B2"
      border={true}
      className="tooltip"
      event="mouseover" // without explicit events' spec, the drop shadow will be rendered ugly in many places
      eventOff="mouseout"
      getContent={(dataTip) => {
        try {
          const dataTipObject = identity<TooltipData>(JSON.parse(dataTip));
          if (dataTipObject && dataTipObject.title !== undefined) {
            return (
              <span>
                <b>
                  {dataTipObject.title}
                  {": "}
                </b>
                {Array.isArray(dataTipObject.content)
                  ? dataTipObject.content.map((value, index) => (
                      <p key={index}>{value}</p>
                    ))
                  : dataTipObject.content}
              </span>
            );
          }
        } catch {
          // return the dataTip as a whole below if it cannot be parsed as JSON
        }

        return dataTip;
      }}
    />
  );
};
