import { ExpandingContentRowHeader } from "./Headers/ExpandingContentRowHeader";
import { WithTranslation, withTranslation } from "react-i18next";

export interface ICollapsibleImprovementSectionProps extends WithTranslation {
  title: string;
  children?: any;
  numItems?: number;
}

function CollapsibleImprovementSectionComponent(
  props: ICollapsibleImprovementSectionProps
) {
  const { title, children, numItems, t } = props;
  return (
    <ExpandingContentRowHeader
      title={title}
      info={
        numItems === 1
          ? t("improvementCountSingle", { amount: numItems })
          : numItems && numItems > 1
          ? t("improvementCountMultiple", { amount: numItems })
          : undefined
      }
    >
      <div className="form-section">{children}</div>
    </ExpandingContentRowHeader>
  );
}

export const CollapsibleImprovementSection = withTranslation("forms")(
  CollapsibleImprovementSectionComponent
);
