import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuditMetaInfo } from "../../modules/auditmetainfo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrganizationCapabilities,
  OrganizationCapabilityType,
} from "../../modules/organization";
import { ReduxState } from "../../reducers";

interface Props {
  auditMetaInfo: AuditMetaInfo;
  placeholder?: ReactElement;
  generatePartHeader?(partKey: string, partTitle: string): ReactElement | null;
  generateThemeHeader?(
    themeKey: string,
    themeTitle: string
  ): ReactElement | null;
  generateContent(
    themeKey: string | null,
    showTopSports: boolean
  ): ReactElement | null;
}

export const ThemeListView = (props: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const federationId = props.auditMetaInfo.federationId;

  useEffect(() => {
    dispatch(fetchOrganizationCapabilities(federationId));
  }, [dispatch, federationId]);

  const federationCapabilities = useSelector(
    (state: ReduxState) => state.organization.capabilities[federationId]
  );

  const showTopSports = federationCapabilities?.some((capability) => {
    return (
      capability.capabilityType ===
        OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
      capability.value === "true"
    );
  });

  const generatePartHeader = props.generatePartHeader
    ? props.generatePartHeader
    : (part: string, partTitle: string) => partTitle;
  const generateThemeHeader = props.generateThemeHeader
    ? props.generateThemeHeader
    : (theme: string, themeTitle: string) => themeTitle;

  const contentForUnsetTheme = props.generateContent(null, showTopSports);
  let hasAnyContent = !!contentForUnsetTheme;

  return (
    <div>
      {!!contentForUnsetTheme && (
        <div className="part-section">
          <div className="theme-section">{contentForUnsetTheme}</div>
        </div>
      )}
      {props.auditMetaInfo.parts.reduce<ReactElement[]>((filtered, part) => {
        const themeReactElements = part.sections.reduce<ReactElement[]>(
          (filtered, section) => {
            const themeReactElement = props.generateContent(
              section.key,
              showTopSports
            );
            if (themeReactElement) {
              hasAnyContent = true;
            }

            return themeReactElement
              ? filtered.concat([
                  <div className="theme-section" key={section.key}>
                    <div className="theme-title">
                      {generateThemeHeader(
                        section.key,
                        t(`forms:${section.key}.formHeader`)
                      )}
                    </div>
                    {themeReactElement}
                  </div>,
                ])
              : filtered;
          },
          []
        );

        // If there are any themes for the given quality path in the current part, add a category item followed
        // by the theme selection items
        if (themeReactElements.length > 0) {
          return filtered.concat(
            <div className="part-section" key={part.key}>
              <div className="part-title">
                {generatePartHeader(part.key, t(`forms:${part.key}.title`))}
              </div>
              {themeReactElements}
            </div>
          );
        } else {
          return filtered;
        }
      }, [])}
      {!hasAnyContent && props.placeholder}
    </div>
  );
};
