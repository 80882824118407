import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./Signout.scss";
import MainRouteViewWrapper from "../MainRouteViewWrapper";
import { LoadingSpinner } from "../../components/LoadingSpinner";

interface SignoutProps extends WithTranslation {}

class Signout extends Component<SignoutProps, any> {
  render() {
    const { t } = this.props;
    return (
      <MainRouteViewWrapper>
        <div className="signoutcontainer">
          <h2 className="text">{t("signout")}</h2>
          <LoadingSpinner />
        </div>
      </MainRouteViewWrapper>
    );
  }
}

export default withTranslation("common")(Signout);
