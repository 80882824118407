import { ReactElement, RefObject, useEffect, useRef } from "react";
import { ThemeListView } from "./ThemeListView";
import { DevelopmentItem } from "../../modules/development";
import { DraggableDevelopmentItemCard } from "../../components/DevelopmentItemCard/DraggableDevelopmentItemCard";
import "./YearContentContainer.scss";
import { useDrop } from "react-dnd";
import { DragTypes } from "./DragTypes";
import { useTranslation } from "react-i18next";
import { AuditMetaInfo } from "../../modules/auditmetainfo";

interface YearContentContainerProps {
  developmentItems: DevelopmentItem[];
  auditMetaInfo: AuditMetaInfo;
  year: number;
  innerRef?: RefObject<HTMLElement>;
  readOnly: boolean;
  onCanDropChanged?(canDrop: boolean): void;
  onDragOverChanged?(isOver: boolean): void;
  onSetItemYear(id: number, year: number | null): void;
}

const createYearViewThemeComponent = (
  developmentItems: DevelopmentItem[],
  itemListElement: HTMLElement | undefined,
  year: number,
  readOnly: boolean
) => (theme: string | null): ReactElement | null => {
  const cardsForTheme = developmentItems
    .filter(
      (item) =>
        ((!item.theme && !theme) || item.theme === theme) && item.year === year
    )
    .map((item, index) => (
      <DraggableDevelopmentItemCard
        item={item}
        itemListElement={itemListElement}
        key={item.id || -index}
        readOnly={readOnly}
      />
    ));

  return cardsForTheme.length > 0 ? (
    <div className="year-view-theme">
      <div className="theme-box">{cardsForTheme}</div>
    </div>
  ) : null;
};

export const YearContentContainer = (props: YearContentContainerProps) => {
  const { t } = useTranslation("clubDevelopment");

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: DragTypes.DEVELOPMENT_ITEM_CARD,
    canDrop: (item: any) => item.developmentItem.year !== props.year,
    drop: (item: any) =>
      props.onSetItemYear(item.developmentItem.id, props.year),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const prevCanDrop = useRef<boolean>();
  const prevIsOver = useRef<boolean>();

  useEffect(() => {
    if (canDrop !== prevCanDrop.current) {
      if (props.onCanDropChanged) {
        props.onCanDropChanged(canDrop);
      }
      prevCanDrop.current = canDrop;
    }
    if (canDrop && isOver !== prevIsOver.current) {
      if (props.onDragOverChanged) {
        props.onDragOverChanged(isOver);
      }
      prevIsOver.current = isOver;
    }
  });

  const itemListRef = useRef(null);

  return (
    <div
      className={`year-content ${
        canDrop
          ? isOver
            ? "year-content-dragover"
            : "year-content-droppable"
          : ""
      }`}
      ref={(instance) => {
        drop(instance);
        (itemListRef as any).current = instance;
        if (props.innerRef) {
          (props.innerRef as any).current = instance;
        }
      }}
    >
      <ThemeListView
        auditMetaInfo={props.auditMetaInfo}
        generateContent={createYearViewThemeComponent(
          props.developmentItems,
          itemListRef.current || undefined,
          props.year,
          props.readOnly
        )}
        placeholder={<div className="placeholder">{t("placeholder")}</div>}
      />
    </div>
  );
};
