import { useEffect } from "react";
import { ReduxState } from "../../reducers";
import { fetchEnrollments, HAS_FETCHED_ENROLLMENTS } from "../../modules/path";
import { fetchAuditRequestsByEnrollment } from "../../modules/auditRequest";
import { connect, useSelector } from "react-redux";
import {
  AuditRequest,
  ClubEnrollment,
  OrgType,
  PathData,
  PathType,
} from "../../model";
import { first, orderBy } from "lodash";
import { ExpandingContentRowHeader } from "../../components/Headers/ExpandingContentRowHeader";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import PathInfoAudit from "../../components/PathInfo/PathInfoAudit";
import "./ClubAuditArchive.scss";
import { OrganizationCapabilityType } from "../../modules/organization";
import { useRouteMatch } from "react-router-dom";

interface ownProps {
  clubId: number;
  enrollments: ClubEnrollment[];
  hasFetchedClubEnrollments: boolean;
  numOfActiveAuditRequestFetches: number;
  auditRequests: AuditRequest[] | {};
  paths: PathData[];
  fetchEnrollments(orgId: number): any;
  fetchRequests(orgId: number): any;
  resetEnrollmentsFetchStatus(orgId: number): any;
}
function ClubAuditArchiveComponent(props: ownProps) {
  const { t } = useTranslation(["common", "forms"]);

  const {
    clubId,
    paths,
    hasFetchedClubEnrollments,
    fetchEnrollments,
    enrollments,
    numOfActiveAuditRequestFetches,
    fetchRequests,
    resetEnrollmentsFetchStatus,
  } = props;

  useEffect(() => {
    fetchEnrollments(clubId);

    return function cleanup() {
      resetEnrollmentsFetchStatus(clubId);
    };
  }, [fetchEnrollments, resetEnrollmentsFetchStatus, clubId]);

  useEffect(() => {
    if (
      enrollments &&
      hasFetchedClubEnrollments &&
      numOfActiveAuditRequestFetches === 0
    ) {
      enrollments.forEach((enrollment) => {
        fetchRequests(enrollment.id!);
      });
    }
  }, [
    enrollments,
    hasFetchedClubEnrollments,
    numOfActiveAuditRequestFetches,
    fetchRequests,
  ]);

  const match = useRouteMatch<any>();
  const { superOrgId } = match?.params;

  const superOrgCapabilities = useSelector((state: ReduxState) =>
    superOrgId ? state.organization.capabilities[superOrgId] : []
  );

  const currentOrgType = useSelector(
    (state: ReduxState) => state.account.currentOrgType
  );

  const currentSuperOrgHasFederationTopSportsCapability =
    currentOrgType === OrgType.ADMIN_ORGANIZATION ||
    superOrgCapabilities?.some((capability) => {
      return (
        capability.capabilityType ===
          OrganizationCapabilityType.SPORTS_FEDERATION_TOP_SPORTS_ENABLED &&
        capability.value === "true"
      );
    });

  function getEnrollmentBasedOnPathType(pathType: PathType) {
    const { enrollments } = props;

    return enrollments.find(
      (enrollment) => enrollment.qualityPath === pathType
    );
  }

  function getActiveRequestBasedOnEnrollment(
    enrollment: ClubEnrollment
  ): AuditRequest {
    const { auditRequests } = props;
    const requests =
      enrollment && auditRequests && auditRequests[enrollment.qualityPath!];

    return requests
      ? first(orderBy(requests, ["lastModifiedTimestamp"], ["desc"]))
      : null;
  }

  function getNonActiveRequestsBasedOnEnrollment(
    enrollment: ClubEnrollment
  ): AuditRequest[] {
    const { auditRequests } = props;
    const activeRequest = getActiveRequestBasedOnEnrollment(enrollment);
    const activeRequestId = activeRequest ? activeRequest.id : null;

    const nonActiveAuditRequests =
      activeRequest && auditRequests
        ? auditRequests[enrollment.qualityPath!].filter(
            (request: AuditRequest) => request.id !== activeRequestId
          )
        : [];

    return orderBy(nonActiveAuditRequests, ["lastModifiedTimestamp"], ["desc"]);
  }

  const hasArchivedAudits = !paths
    ? false
    : paths
        .map((path) => {
          const enrollment = getEnrollmentBasedOnPathType(path.type);
          const nonActiveRequests = getNonActiveRequestsBasedOnEnrollment(
            enrollment!
          );

          return nonActiveRequests.length;
        })
        .some((nonActiveRequest) => {
          return nonActiveRequest > 0;
        });

  return (
    <div className="archive-container">
      <h3>{t("clubPathArchive.archiveHeading")}</h3>
      {hasArchivedAudits ? (
        hasFetchedClubEnrollments &&
        paths &&
        paths.map((path) => {
          if (
            !currentSuperOrgHasFederationTopSportsCapability &&
            path.type === PathType.TOP_SPORTS
          ) {
            return null;
          }

          const enrollment = getEnrollmentBasedOnPathType(path.type);
          const nonActiveRequests = getNonActiveRequestsBasedOnEnrollment(
            enrollment!
          );

          return nonActiveRequests.map((request) => {
            return (
              <ExpandingContentRowHeader
                title={t(`forms:path.type.${path.type}`)}
                subTitle={[
                  t(`clubPathArchive.auditAcceptedSubTitle`),
                  `${format(request.lastModifiedTimestamp, "DD.MM.YYYY")}`,
                ].join(" ")}
                key={request.id}
                className="archived"
              >
                <PathInfoAudit
                  key={request.id}
                  data={path}
                  auditRequest={request}
                  isArchived={true}
                />
              </ExpandingContentRowHeader>
            );
          });
        })
      ) : (
        <div className="no-archived-audits-text">
          {t("clubPathArchive.noArchivedAudits")}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: ReduxState, props: any) => {
  const enrollments = state.path.clubEnrollments || [];
  const hasFetchedClubEnrollments =
    state.path.fetchedClubEnrollments[props.clubId];
  const numOfActiveAuditRequestFetches =
    state.auditRequest.numOfActiveAuditRequestFetches;
  const auditRequests = {};
  enrollments.forEach((enrollment) => {
    auditRequests[enrollment.qualityPath!] =
      state.auditRequest.requestLists[enrollment.id!];
  });
  return {
    paths: state.path.paths,
    enrollments: enrollments,
    hasFetchedClubEnrollments: hasFetchedClubEnrollments,
    numOfActiveAuditRequestFetches: numOfActiveAuditRequestFetches,
    auditRequests: auditRequests, // audit requests per path type here: auditRequests['YOUTH'] => [{req1},{req2}]
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchEnrollments: (orgId) => dispatch(fetchEnrollments(orgId)),
  fetchRequests: (enrollmentId: number) =>
    dispatch(fetchAuditRequestsByEnrollment(enrollmentId)), //state.auditRequest.requestLists[enrollment.id!]
  resetEnrollmentsFetchStatus: (orgId: number) =>
    dispatch(HAS_FETCHED_ENROLLMENTS({ id: orgId, data: false })),
});

export const ClubAuditArchive = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubAuditArchiveComponent);
