import { Component } from "react";
import { HelpBlock, FormGroup } from "react-bootstrap";
import Select from "react-select";

import "react-select/dist/react-select.css";
import FormFieldDescription from "./components/FormFieldDescription";
import DescriptionField from "./DescriptionField";
import { AttachmentAuditField } from "./AttachmentAuditField";

export interface Option {
  formattedName?: string;
  description?: string;
  id?: any;
  label?: any;
  value?: any;
  category?: string;
}

interface SelectFieldProps {
  addPlaceholder?: boolean;
  keyName: string;
  label?: string;
  labelClassName?: string;
  valueName: string;
  className?: string;
  disabled?: boolean;
  input: {
    value: any;
    name: string;
    onChange(value: any): void;
    onBlur(value: any): void;
  };
  options: Option[];
  placeholder?: string;
  helpText?: string;
  meta: {
    invalid: boolean;
    error: string;
    touched: boolean;
    form: string;
  };
  wrapperClassNames?: string;
  searchable?: boolean;
  formfieldDesc?: string;
  showAddDesc: boolean;
  showAttachmentField?: boolean;
  descriptionEdit: boolean;
  partKey?: string;
  orgId?: string;
  autosave?: boolean;
  edit?: boolean;
  hideInput?: boolean;
}

class OptionComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseDown(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event: any) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event: any) {
    if (this.props.isFocused) {
      return;
    }
    this.props.onFocus(this.props.option, event);
  }

  render() {
    const { option } = this.props;

    if (option.category) {
      return (
        <div className="suggestioncategory">
          <span>{option.category}</span>
        </div>
      );
    }

    return (
      <div
        className="suggestionitem"
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <div className="suggestioniteminner">
          <div className="suggestiontitle">
            <span>{option.label}</span>
          </div>
          {option.description && (
            <div className="suggestionsubtitle">
              <span>{option.description}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class SelectField extends Component<SelectFieldProps, object> {
  render() {
    const {
      labelClassName,
      input,
      label,
      disabled,
      placeholder,
      helpText,
      options,
      meta: { invalid, error, touched, form },
      wrapperClassNames,
      searchable,
      className,
      formfieldDesc,
      showAddDesc,
      descriptionEdit,
      showAttachmentField,
      orgId,
      autosave,
      edit,
      hideInput,
    } = this.props;

    let usedClassNames = wrapperClassNames
      ? `${wrapperClassNames} formfield`
      : "formfield";
    usedClassNames = disabled ? `${usedClassNames} disabled` : usedClassNames;

    const usedLabelClassName = labelClassName
      ? `formlabel ${labelClassName}`
      : "formlabel";

    return (
      <FormGroup
        controlId={placeholder}
        className={className ? className + " selectfield" : "selectfield"}
        validationState={invalid && error && touched ? "error" : "success"}
      >
        {label && (
          <div className="fieldlabel">
            <label className={usedLabelClassName}>{label}</label>
            <FormFieldDescription>{helpText}</FormFieldDescription>
          </div>
        )}
        <div className={usedClassNames}>
          {formfieldDesc && (
            <div style={{ marginBottom: "3px", fontSize: "14px" }}>
              <b>{formfieldDesc}</b>
            </div>
          )}
          <div className="profile-select-wrapper">
            {!hideInput && (
              <Select
                disabled={disabled}
                placeholder={placeholder}
                options={options}
                optionComponent={OptionComponent}
                searchable={searchable ? searchable : false}
                clearable={false}
                simpleValue={true}
                {...input}
                onBlur={() => {
                  input.onBlur(input.value);
                  if (autosave) {
                    // Event for PartFormWrapper, update the field value right away
                    const fieldUpdateEvent = new CustomEvent(
                      "updateSingleField",
                      {
                        detail: { fieldName: input.name, value: input.value },
                      }
                    );
                    window.dispatchEvent(fieldUpdateEvent);
                  }
                }}
              />
            )}
          </div>
          {invalid && error && touched ? <HelpBlock>{error}</HelpBlock> : null}
          {showAddDesc && (
            <DescriptionField
              name={input.name + "Desc"}
              form={form}
              edit={descriptionEdit && !disabled}
            />
          )}
          {showAttachmentField && orgId && (
            <AttachmentAuditField
              name={input.name + "Attach"}
              orgId={orgId}
              readOnly={!descriptionEdit || disabled}
              edit={edit}
            />
          )}
        </div>
      </FormGroup>
    );
  }
}

export default SelectField;
