import { Fragment, Component } from "react";
import { PartFormProps } from "../../../../model";
import { translatedYesNoMap, translatedYesNoOptions } from "../model";
import { WithTranslation } from "react-i18next";
import {
  FormSectionWithContents,
  TahtiseuraFormSectionWithContentsProps,
} from "../../FormBuildHelpers";

/*
 The form structure of a path part, used together with PathFormWrapper, which performs the necessary
 data operations for the form. Props that are passed to PartFormWrapper are passed along to this component
 */
export class TopSportsBasicCriteriaPart extends Component<
  PartFormProps & WithTranslation,
  any
> {
  render() {
    const {
      t,
      commentEdit,
      orgId,
      formData,
      tools,
      readOnlyMode,
      formMetaInfo,
      formVersion,
      sportsFederationTranslationKeyMap,
    } = this.props;
    const type = this.props.pathType;

    const readOnly = readOnlyMode;
    const partThemes =
      tools && tools.items && tools.items.length === 1
        ? tools.items[0].fields.partThemes
        : {};

    const yesNoOptions = translatedYesNoOptions(t);
    const yesNoRatingMap = translatedYesNoMap(t);

    const formSectionProps: Omit<
      TahtiseuraFormSectionWithContentsProps,
      "partKey" | "sectionKey"
    > = {
      orgId,
      formVersion,
      formMetaInfo,
      sportsFederationTranslationKeyMap,
      t,
      formData,
      options: yesNoOptions,
      ratingMap: yesNoRatingMap,
      type,
      partThemes,
      readOnly,
      commentEdit,
    };

    return (
      <Fragment>
        <FormSectionWithContents
          {...formSectionProps}
          partKey="basiccriteria"
          sectionKey="topsports.basiccriteria.success"
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey={"basiccriteria"}
          sectionKey={"topsports.basiccriteria.commitment"}
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey="basiccriteria"
          sectionKey="topsports.basiccriteria.cooperation"
        />
        <FormSectionWithContents
          {...formSectionProps}
          partKey="basiccriteria"
          sectionKey="topsports.basiccriteria.youthtahtiseura"
        />
      </Fragment>
    );
  }
}

export default TopSportsBasicCriteriaPart as any;
