// tslint:disable
import { useState, useEffect } from "react";
import { Table, Column, AutoSizer } from "react-virtualized";
import { orderBy, dropRight, last } from "lodash";
import { format } from "date-fns";
import "./Tables.scss";
import { useTranslation } from "react-i18next";
import pencilIcon from "../../images/pencil-icon.svg";
import removeIcon from "../../images/remove.svg";
import { Modal } from "react-bootstrap";
import {
  Attachment,
  removeAttachment,
  updateAttachment,
} from "../../modules/attachment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Alert from "react-s-alert";

interface Props {
  list: any[];
  allowEdit?: boolean;
  allowDelete?: boolean;
  modalHeight?: boolean;
  selectedAttachments?: Set<number>;
  setSelectedAttachments?: any;
}

interface AttachmentsTableState {
  disableHeader: boolean;
  headerHeight: number;
  height: number;
  hideIndexRow: boolean;
  overscanRowCount: number;
  rowHeight: number;
  rowCount: number;
  scrollToIndex: any;
  useDynamicRowHeight: boolean;
  sortedList?: any;
  correctionsFetched: boolean;
  sortBy?: string;
  sortDirection?: string;
}

export function AttachmentsTable(props: Props) {
  const [state, setState] = useState<AttachmentsTableState>({
    disableHeader: false,
    headerHeight: 30,
    height: 270,
    hideIndexRow: false,
    overscanRowCount: 10,
    rowHeight: 40,
    scrollToIndex: undefined,
    useDynamicRowHeight: false,
    correctionsFetched: false,
    sortedList: [],
    rowCount: 0,
  });
  const [editModal, setModifyModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeFile, setActiveFile] = useState<number>();
  const [showUnused, setShowUnused] = useState(false);
  const { orgId } = useParams<any>();
  const [t] = useTranslation("common");
  const dispatch = useDispatch();

  useEffect(() => {
    var oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const formattedList = props.list
      .filter(
        (one: Attachment) =>
          showUnused || new Date(one.lastUsedTimestamp) > oneYearAgo
      )
      .map((one: Attachment) => {
        const fileNameArray = one.name ? one.name.split(".") : one.name;
        const hasFileType = Array.isArray(fileNameArray)
          ? fileNameArray.length > 1
          : false;
        return {
          id: one.id,
          name: hasFileType
            ? dropRight(one.name.split("."), 1).join(".")
            : one.name,
          type: hasFileType ? last(one.name.split(".")) : "",
          created: one.creationTimestamp,
          url: one.url,
        };
      });
    const sortedList = orderBy(formattedList, ["added"], ["asc"]);
    setState((prev) => ({ ...prev, sortedList, rowCount: sortedList.length }));
  }, [props.list, showUnused]);

  const removeOrgAttachment = async () => {
    if (activeFile && orgId) {
      try {
        await dispatch(removeAttachment(orgId, activeFile));
        Alert.info(t("common:apiAlerts.removeAttachment"));
      } catch (err) {
        err.message === "LOCKED"
          ? Alert.error(t("common:apiErrors.removeLockedAttachment"))
          : Alert.error(t("common:apiErrors.removeAttachment"));
      }
      setDeleteModal(false);
    }
  };

  const renameOrgAttachment = async () => {
    if (activeFile && orgId) {
      const response = await dispatch(
        updateAttachment(orgId, activeFile, fileName)
      );
      !!response
        ? Alert.info(t("common:apiAlerts.updateAttachment"))
        : Alert.error(t("common:apiErrors.updateAttachment"));
      setModifyModal(false);
    }
  };

  const _noRowsRenderer = () => {
    return <div className="empty-row">{t("attachments.table.empty")}</div>;
  };

  const _rowClassName = ({ index }: any) => {
    if (index < 0) {
      return "attachments-row even-row";
    } else {
      return index % 2 === 0
        ? "attachments-row even-row"
        : "attachments-row odd-row";
    }
  };

  const _sort = ({ sortBy, sortDirection }: any) => {
    const sortedList = orderBy(
      state.sortedList,
      [sortBy],
      [sortDirection.toLowerCase()]
    );

    setState({ ...state, sortBy, sortDirection, sortedList });
  };

  const headerRowRenderer = ({ className, columns, style }) => (
    <div className={className.concat(" header-row")} role="row" style={style}>
      {columns}
    </div>
  );

  const rowGetter = ({ index }) => {
    return sortedList[index];
  };

  const toggleFromSet = (id: number) => {
    const { selectedAttachments } = props;
    let newSet = new Set(selectedAttachments);
    if (selectedAttachments && selectedAttachments.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    return newSet;
  };

  const renderNameColumn = ({ cellData, rowData }) => {
    const { setSelectedAttachments, selectedAttachments } = props;
    if (setSelectedAttachments && selectedAttachments) {
      return (
        <div className="checkbox-field-item">
          <input
            id="terms"
            type="checkbox"
            data-id="terms"
            checked={selectedAttachments.has(rowData.id)}
            readOnly={true}
            className="styledcheckbox"
          />
          <span
            className="checkmark"
            onClick={() => setSelectedAttachments(toggleFromSet(rowData.id))}
            onKeyPress={() => setSelectedAttachments(toggleFromSet(rowData.id))}
            tabIndex={0}
            aria-checked={selectedAttachments.has(rowData.id)}
            role="checkbox"
            aria-label={`${t("attachments.add-modal.agree")} ${t(
              "attachments.add-modal.terms"
            )}`}
          />
          <label
            className="checkboxfieldlabel"
            htmlFor="terms"
            onClick={() => setSelectedAttachments(toggleFromSet(rowData.id))}
          >
            {cellData}
          </label>
        </div>
      );
    } else {
      return (
        <a
          className="name"
          href={rowData.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cellData}
        </a>
      );
    }
  };

  const { scrollToIndex, sortBy, sortDirection, sortedList } = state;

  const { allowEdit, allowDelete, modalHeight } = props;

  const tableHeight = modalHeight
    ? 300
    : (sortedList.length + 2) * 40 < 500
    ? (sortedList.length + 2) * 40
    : 500;

  return (
    <div className="table-container">
      <div className="show-unused-attachments">
        <input
          id="showUnused"
          type="checkbox"
          onChange={(e) => setShowUnused(e.target.checked)}
          className="styledcheckbox"
        />
        <label className="checkboxfieldlabel" htmlFor="showUnused">
          {t("attachments.table.showUnused")}
        </label>
      </div>
      <AutoSizer disableHeight={true} style={{ width: "100%" }}>
        {({ width }) => (
          <Table
            className="attachments-table"
            headerHeight={45}
            headerClassName="attachments-table-header"
            headerRowRenderer={headerRowRenderer}
            noRowsRenderer={_noRowsRenderer}
            rowClassName={_rowClassName}
            height={tableHeight}
            rowHeight={40}
            rowGetter={rowGetter}
            rowCount={sortedList.length}
            scrollToIndex={scrollToIndex}
            sort={_sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            width={width}
          >
            <Column
              label={t("attachments.table.name")}
              dataKey="name"
              width={width < 400 ? 300 : 400}
              className={"tablecolumn"}
              cellRenderer={renderNameColumn}
            />
            {width > 400 && (
              <Column
                width={100}
                dataKey="type"
                label={t("attachments.table.type")}
                className={"tablecolumn"}
              />
            )}
            <Column
              width={180}
              dataKey="created"
              cellRenderer={({ cellData }) =>
                cellData ? format(cellData, "DD.MM.YYYY") : null
              }
              label={t("attachments.table.created")}
              className={"tablecolumn"}
            />
            {allowEdit && (
              <Column
                width={50}
                dataKey=""
                disableSort={false}
                className={"tablecolumn"}
                cellRenderer={({ rowData }) => (
                  <button
                    className="btn-invisible"
                    title={t("attachments.rename")}
                    onClick={() => {
                      setFileName(`${rowData.name}.${rowData.type}`);
                      setActiveFile(rowData.id);
                      setModifyModal(true);
                    }}
                  >
                    <img alt="Edit" src={pencilIcon} />
                  </button>
                )}
              />
            )}
            {allowDelete && (
              <Column
                width={65}
                dataKey=""
                disableSort={false}
                className={"tablecolumn"}
                cellRenderer={({ rowData }) => (
                  <button
                    className="btn-invisible"
                    title={t("attachments.delete")}
                    onClick={() => {
                      setDeleteModal(true);
                      setActiveFile(rowData.id);
                    }}
                  >
                    <img alt="Edit" src={removeIcon} />
                  </button>
                )}
              />
            )}
          </Table>
        )}
      </AutoSizer>
      {allowEdit && (
        <Modal
          show={editModal}
          onHide={() => setModifyModal(false)}
          dialogClassName="attachment-modal"
          className="centered-modal"
        >
          <Modal.Body>
            <h1>{t("attachments.modify-modal.title")}</h1>
            <div className="field">
              <label htmlFor="name">{t("attachments.modify-modal.name")}</label>
              <input
                name="name"
                value={fileName}
                placeholder={t("attachments.modify-modal.placeholder")}
                onChange={(e) => setFileName(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => {
                  renameOrgAttachment();
                }}
                disabled={!fileName}
              >
                {t("attachments.modify-modal.save")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => setModifyModal(false)}
              >
                {t("modal.cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {allowDelete && (
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          dialogClassName="attachment-modal"
          className="centered-modal"
        >
          <Modal.Body>
            <h1>{t("attachments.delete-modal.title")}</h1>
          </Modal.Body>
          <Modal.Footer>
            <div className="modalbuttons">
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => {
                  removeOrgAttachment();
                }}
              >
                {t("attachments.delete-modal.delete")}
              </button>
              <button
                className="btn btn-lg btn-secondary-blue"
                onClick={() => setDeleteModal(false)}
              >
                {t("modal.cancel")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
