import { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./SubNav.scss";
import { UserData } from "../../modules/account";
import { connect } from "react-redux";
import { ReduxState } from "../../reducers";
import { RouteComponentProps, withRouter } from "react-router";

enum NavigationMode {
  MINIMAL = "MINIMAL",
  NORMAL = "NORMAL",
}

/*
  SubNavigation for main views, use with HighlightNavItem
 */
export interface NavigationProps
  extends RouteComponentProps<{}>,
    WithTranslation {
  children?: any;
  user: UserData | null;
}

interface NavigationState {
  isNavigationInitialized: boolean;
  mode: NavigationMode;
}

class Navigation extends Component<NavigationProps, NavigationState> {
  constructor(props: NavigationProps) {
    super(props);

    this.state = {
      isNavigationInitialized: false,
      mode: NavigationMode.NORMAL,
    };
  }

  handleRoleChange = () => {};

  render() {
    return (
      <div className="subnav">
        <ul>{this.props.children}</ul>
      </div>
    );
  }
}

const navTranslated = withTranslation("menu")(Navigation);

const mapStateToProps = (state: ReduxState) => ({
  user: state.account.user,
});

const mapDispatchToProps = () => ({});

const NavigationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(navTranslated);

export default withRouter(NavigationConnected);
