import { Suspense } from "react";
import * as React from "react";
import { Store } from "redux";
import { Provider } from "react-redux";
import { SICLProvider } from "@suomisport/ui-components/components/SICLProvider";

import "./styles/app.scss";
import App from "./views/app/App";
import { BrowserRouter as Router } from "react-router-dom";
// history.js
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

interface AppProps {
  store: Store<{}>;
}

const Root: React.FunctionComponent<AppProps> = ({ store }) => {
  return (
    <SICLProvider>
      <Suspense fallback="loading">
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </Suspense>
    </SICLProvider>
  );
};

export default Root;
