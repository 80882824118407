import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AttachmentsTable } from "../Tables/AttachmentsTable";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "../../reducers";
import {
  SET_ATTACHMENT_MODAL_DATA,
  uploadAttachment,
  Attachment,
} from "../../modules/attachment";
import { useParams } from "react-router";
import Alert from "react-s-alert";
import * as Spinner from "react-spinkit";
import Axios, { CancelTokenSource } from "axios";

const MAX_ATTACHMENT_SIZE = 10000000;

interface AttachmentModalProps {
  linkedAttachments?: Attachment[] | null;
  formLinkMode: boolean;
  linkAttachmentsToField?(attachmentIds: number[]): void;
}

export function AttachmentModal(props: AttachmentModalProps) {
  const [file, setFile] = useState<any>(null);
  const [terms, setTerms] = useState(false);
  const [selected, setSelected] = useState<Set<number>>(new Set([]));
  const [loading, setLoading] = useState(false);
  const [
    cancelTokenSource,
    setCancelTokenSource,
  ] = useState<CancelTokenSource | null>(null);
  let fileInputRef: HTMLInputElement | null = null;

  const { modalData, list } = useSelector((state: ReduxState) => ({
    modalData: state.attachment.modalData,
    list: state.attachment.orgAttachments,
  }));

  const dispatch = useDispatch();
  const hideModal = () => {
    // cleanup state and redux when hiding modal
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
      setCancelTokenSource(null);
    }
    setFile(null);
    setTerms(false);
    setSelected(new Set([]));
    return dispatch(
      SET_ATTACHMENT_MODAL_DATA({
        modalOpen: false,
        showLinkTable: false,
        fieldName: undefined,
        orgId: undefined,
        partKey: undefined,
        requestId: undefined,
        auditRequest: undefined,
        enrollment: undefined,
      })
    );
  };

  const upload = (orgId): any => {
    let tmpCancelTokenSource = Axios.CancelToken.source();
    setCancelTokenSource(tmpCancelTokenSource);
    return dispatch(uploadAttachment(file, orgId, tmpCancelTokenSource.token));
  };

  const { t } = useTranslation("common");

  const { modalOpen, showLinkTable } = modalData;
  const { orgId } = useParams<any>();

  const linkAttachmentsToField = async () => {
    setLoading(true);
    if (file && orgId) {
      try {
        const attachment = await upload(orgId);
        // compile a list of new attachments to the field
        const newIds = linkedAttachments
          ? linkedAttachments.map((x) => x.id).concat(attachment.id)
          : [attachment.id];
        const added = new Set(selected);
        // add them to the already linked ones
        newIds.forEach((id) => added.add(id));

        props.linkAttachmentsToField!(Array.from(added));
      } catch (err) {
        err.message === "NAMECONFLICT"
          ? Alert.error(t("common:apiErrors.attachmentNamingConflict"))
          : Alert.error(t("common:apiErrors.linkAttachment"));
      }
      // a new file selected from the computer, add it to the attachment list and link the list to a field
    } else {
      // only link the selected attachments
      try {
        props.linkAttachmentsToField!(
          Array.from(selected).concat(
            linkedAttachments ? linkedAttachments.map((x) => x.id) : []
          )
        );
      } catch {
        Alert.error(t("common:apiErrors.linkAttachment"));
      }
    }
    setLoading(false);

    hideModal();
  };

  const uploadFile = async () => {
    // if the user is just adding the file in to the org's attachments
    if (file && orgId) {
      setLoading(true);
      try {
        await upload(orgId);
        Alert.info(t("common:apiAlerts.uploadAttachment"));
      } catch (err) {
        err.message === "NAMECONFLICT"
          ? Alert.error(t("common:apiErrors.attachmentNamingConflict"))
          : Alert.error(t("common:apiErrors.uploadAttachment"));
      }
      setLoading(false);
    }
    hideModal();
  };

  const setFileToState = async (files: FileList | null) => {
    const file = files && files[0];
    if (!file) {
      return;
    }
    if (file.size > MAX_ATTACHMENT_SIZE) {
      Alert.error(t("apiErrors.attachmentTooLarge"));
      if (fileInputRef) {
        fileInputRef.value = "";
      }
      return;
    } else {
      setFile(file);
    }
  };

  const { linkedAttachments } = props;
  // filter out already selected attachments from the list
  const filteredFileList = linkedAttachments
    ? list.filter((x) => !linkedAttachments.map((one) => one.id).includes(x.id))
    : list;

  return (
    <Modal
      show={modalOpen}
      onHide={() => hideModal()}
      dialogClassName={`attachment-modal ${showLinkTable ? "link-table" : ""}`}
      className="centered-modal"
    >
      <Modal.Body>
        <h1>
          {t(
            showLinkTable
              ? "attachments.link-modal.title"
              : "attachments.add-modal.title"
          )}
        </h1>
        {showLinkTable && <h2>{t("attachments.link-modal.computer")}</h2>}
        <div className="file-browser">
          <input
            ref={(ref) => (fileInputRef = ref)}
            id="file-dialog-button"
            name="file"
            type="file"
            tabIndex={-1}
            onChange={(e) => setFileToState(e.target.files)}
          />
          <span className={file ? "file-added" : ""}>
            {file ? file.name : t("attachments.add-modal.placeholder")}
          </span>
          <label
            htmlFor="file"
            className="btn btn-lg btn-secondary-blue"
            tabIndex={0}
            role="button"
            onClick={() =>
              document.getElementById("file-dialog-button")!.click()
            }
            onKeyPress={() =>
              document.getElementById("file-dialog-button")!.click()
            }
            aria-label={t("attachments.add-modal.browse")}
          >
            {t("attachments.add-modal.browse")}
          </label>
        </div>
        <div className="checkbox-field-item">
          <input
            id="terms"
            type="checkbox"
            data-id="terms"
            checked={terms}
            readOnly={true}
            className="styledcheckbox"
          />
          <span
            className="checkmark"
            onClick={() => setTerms(!terms)}
            onKeyPress={() => setTerms(!terms)}
            tabIndex={0}
            aria-checked={terms}
            role="checkbox"
            aria-label={`${t("attachments.add-modal.agree")} ${t(
              "attachments.add-modal.terms"
            )}`}
          />
          <label
            className="checkboxfieldlabel"
            htmlFor="terms"
            onClick={() => setTerms(!terms)}
          >
            {`${t("attachments.add-modal.agree")} `}
            <a
              href="https://www.olympiakomitea.fi/uploads/2019/11/6db76792-kayttoehdot_liitepankki_tahtiseura-verkkopalvelu.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("attachments.add-modal.terms")}
            </a>
          </label>
        </div>
        {showLinkTable && (
          <>
            <hr />
            <h2>{t("attachments.link-modal.attachmentbank")}</h2>
            <AttachmentsTable
              selectedAttachments={selected}
              setSelectedAttachments={(list) => setSelected(list)}
              list={filteredFileList}
              allowDelete={false}
              allowEdit={false}
              modalHeight={true}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modalbuttons">
          <button
            className="btn btn-lg btn-secondary-blue"
            onClick={() => {
              props.formLinkMode ? linkAttachmentsToField() : uploadFile();
            }}
            disabled={(file && !terms) || (selected.size === 0 && !file)}
          >
            {loading ? (
              <Spinner
                name="three-bounce"
                style={{ height: "100%" }}
                fadeIn={"none"}
                color={"white"}
              />
            ) : (
              t("attachments.link-modal.link")
            )}
          </button>
          <button
            className="btn btn-lg btn-secondary-blue"
            onClick={() => hideModal()}
          >
            {t("modal.cancel")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
