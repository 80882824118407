import "./DevelopmentItemSummaryCard.scss";
import { DevelopmentItemStatus } from "../../model";
import personIcon from "../../images/person-large.svg";
import statusOngoingIcon from "../../images/status-ongoing.svg";
import statusReadyIcon from "../../images/status-ready.svg";
import statusNotStartedIcon from "../../images/status-notstarted.svg";
import { DevelopmentItem } from "../../modules/development";
import { DevelopmentItemCardFrame } from "./DevelopmentItemCardFrame";
import { useTranslation } from "react-i18next";
import { DevelopmentItemMenu } from "./DevelopmentItemMenu";
import { DevelopmentItemStatusRow } from "./DevelopmentItemStatusRow";
import { DEVELOPMENT_ITEM_SUMMARY_TOOLTIP_ID } from "../../views/clubdevelopment/DevelopmentSummary";

interface DevelopmentItemSummaryCardProps {
  item: DevelopmentItem;
  readonly: boolean;
}

export function DevelopmentItemSummaryCard(
  props: DevelopmentItemSummaryCardProps
) {
  const { t } = useTranslation("clubDevelopment");

  const passive = props.item.locked || !props.item.year;
  const height = passive ? 70 : 110;
  const assigneeArray = props.item.assignees;
  const assignee =
    assigneeArray &&
    assigneeArray.length > 0 &&
    assigneeArray.find((assignee) => assignee.trim().length > 0);

  return (
    <DevelopmentItemCardFrame
      itemProps={props.item}
      className="development-item-summary-card"
      height={height}
    >
      <div className="left-content">
        <div className="description">{props.item.description}</div>
        {!passive && <div className="year">{props.item.year}</div>}
      </div>
      <div className="right-content">
        {!passive && (
          <div className="inforow">
            <div className="status">
              <img
                src={
                  props.item.status === DevelopmentItemStatus.NOT_STARTED ||
                  !props.item.status
                    ? // if there is a year assigned, display it as "not started", even though the status is not defined
                      statusNotStartedIcon
                    : props.item.status === DevelopmentItemStatus.ONGOING
                    ? statusOngoingIcon
                    : props.item.status === DevelopmentItemStatus.READY
                    ? statusReadyIcon
                    : null
                }
                alt={t("itemCard.status-alt")}
                className="statusicon"
              />
              <div className="statusdesc">
                {props.item.status === DevelopmentItemStatus.READY
                  ? t("itemStatus.ready")
                  : props.item.status === DevelopmentItemStatus.ONGOING
                  ? t("itemStatus.ongoing")
                  : t("itemStatus.notStarted")}
              </div>
            </div>
            <DevelopmentItemStatusRow
              item={props.item}
              showPriority={true}
              tooltipId={DEVELOPMENT_ITEM_SUMMARY_TOOLTIP_ID}
            />
          </div>
        )}
        {!passive && (
          <div className="personrow">
            <img
              src={personIcon}
              alt={t("itemCard.person-alt")}
              className={`personicon ${!assignee ? "not-assigned" : ""}`}
            />
            <div className="assignee">{assignee}</div>
          </div>
        )}
      </div>
      <DevelopmentItemMenu
        item={props.item}
        readOnly={props.readonly}
        tooltipId={DEVELOPMENT_ITEM_SUMMARY_TOOLTIP_ID}
      />
    </DevelopmentItemCardFrame>
  );
}
