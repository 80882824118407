import { Component } from "react";
import { FormGroup, HelpBlock, Radio } from "react-bootstrap";
import { v4 } from "uuid";
import RadioButtonSubField from "./RadioButtonSubField";
import FormFieldDescription from "./components/FormFieldDescription";
import { errorText, isInvalid } from "../../../util/validationHelper";

interface RadioButtonGroupFieldProps {
  className?: string;
  disabled: boolean;
  helpText: string;
  names: string[];
  options: any;
  optionsTitleText: string;
  placeholder: string;
  placeholderShowOptions: string;
  showOptions: any;
}

class RadioButtonGroupField extends Component<RadioButtonGroupFieldProps, any> {
  constructor(props: RadioButtonGroupFieldProps) {
    super(props);
    this.options = this.options.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: any) {
    const { names, showOptions } = this.props;
    this.props[names[showOptions ? 1 : 0]].input.onChange(event.target.value);
  }

  getRandomName() {
    return Math.random().toString(36).substr(2, 5);
  }

  options() {
    const { className, names, options, showOptions, disabled } = this.props;
    const randomName = this.getRandomName();
    const input = this.props[names[showOptions ? 1 : 0]].input;

    const buttons = () =>
      options.map((option) => {
        return (
          <span
            key={this.getRandomName()}
            style={{
              display: "flex",
              flex: "1 1 100%",
              flexDirection: "column",
            }}
          >
            <Radio
              type="radio"
              id={randomName + option.key}
              name={randomName}
              value={option.key}
              onChange={this.onChange}
              checked={input.value === option.key}
              disabled={disabled}
            >
              {option.value}
            </Radio>
            <div
              style={{ marginLeft: "20px" }}
              className="correctiondate"
              key={this.getRandomName()}
            >
              {option.extra}
            </div>
          </span>
        );
      });

    return <div className={className}>{buttons()}</div>;
  }

  render() {
    const {
      disabled,
      names,
      showOptions,
      placeholder,
      placeholderShowOptions,
      helpText,
    } = this.props;

    return (
      <FormGroup
        className="radiobuttongroup"
        controlId={placeholder}
        validationState={isInvalid(names, this.props) ? "error" : "success"}
      >
        {placeholder ? (
          <div className="fieldlabel">
            <label className="formlabel">{placeholder}</label>
            <FormFieldDescription>{helpText}</FormFieldDescription>
          </div>
        ) : null}

        <div className="formfield">
          {showOptions ? (
            <div>
              <RadioButtonSubField
                placeholder={placeholderShowOptions}
                input={this.props[names[0]].input}
                options={showOptions}
                disabled={disabled}
              />
              {this.props[names[0]].input.value === "YES"
                ? this.options()
                : null}
            </div>
          ) : (
            this.options()
          )}

          {isInvalid(names, this.props) ? (
            <HelpBlock>
              {errorText(names, this.props).map((err) => {
                return <span key={v4()}>{err}</span>;
              })}
            </HelpBlock>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

export default RadioButtonGroupField;
