import { Component, ErrorInfo } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";

interface ErrorBoundaryProps extends WithTranslation {
  setGlobalError?(): void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const styles: any = {
  container: {
    color: "#FFF",
    display: "flex",
    width: "100vw",
    flex: "1 1 auto",
    height: "100vh",
    backgroundImage: "linear-gradient(to bottom, #29a1d5, #23beb8)",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "0.3px",
  },
  innerContainer: {
    width: "600px",
    textAlign: "center",
    marginBottom: "100px",
  },
  errorText: {
    display: "flex",
    flexDirecton: "column",
    fontSize: "36px",
    marginBottom: "10px",
    marginTop: "60px",
    justifyContent: "center",
  },
  errorText2: { fontSize: "13px" },
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.warn(Error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ paddingTop: "50px" }}>
          <div style={styles.container}>
            <div style={styles.innerContainer}>
              <span style={styles.errorText}>
                {this.props.t("error.text1")}
              </span>
              <span style={styles.errorText2}>
                {this.props.t("error.text2")}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default connect(null, (dispatch) => ({}))(
  withTranslation(["common"])(ErrorBoundary as any)
) as any;
