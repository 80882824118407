import { Component } from "react";
import { AuditRequest, ClubEnrollment, PathType } from "../../../model";
import "./PathFormV3.scss";
import YouthSportContentPart from "./SportContentPart/YouthSportContentPart";
import AdultsSportContentPart from "./SportContentPart/AdultsSportContentPart";
import YouthManagementPart from "./ManagementPart/YouthManagementPart";
import AdultsManagementPart from "./ManagementPart/AdultsManagementPart";
import YouthResourcesPart from "./ResourcesPart/YouthResourcesPart";
import AdultsResourcesPart from "./ResourcesPart/AdultsResourcesPart";
import YouthPeoplePart from "./PeoplePart/YouthPeoplePart";
import AdultsPeoplePart from "./PeoplePart/AdultsPeoplePart";
import PathFormWrapper from "../PartFormWrapper";
import CommentModal from "../../../components/CommentModal/CommentModal";
import { translatedOptions, translatedRatingMap } from "./model";
import { withTranslation, WithTranslation } from "react-i18next";
import { AttachmentAuditModal } from "../../AttachmentModal/AttachmentAuditModal";

type PathFormV3Props = {
  currentOrg: number | null;
  enrollment: ClubEnrollment | null | undefined;
  activeRequest: AuditRequest | null | undefined;
  readOnlyMode: boolean;
  commentEdit: boolean;
  clubMode: boolean;
  formPart: string;
  pathType: string;
};

class PathFormV3 extends Component<PathFormV3Props & WithTranslation, any> {
  render() {
    const {
      t,
      currentOrg,
      enrollment,
      activeRequest,
      readOnlyMode,
      commentEdit,
      clubMode,
      pathType,
      formPart,
    } = this.props;
    if (!currentOrg) {
      return null;
    }
    let formContent: any = null;

    const options = translatedOptions(t);
    const ratingMap = translatedRatingMap(t);

    switch (formPart) {
      case "sportcontent":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}sportcontent`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="sportcontent"
              toolsKey="youthSportcontent"
            >
              <YouthSportContentPart />
            </PathFormWrapper>
          ) : (
            <PathFormWrapper
              form={`${pathType}sportcontent`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="sportcontent"
              toolsKey="adultsSportcontent"
            >
              <AdultsSportContentPart />
            </PathFormWrapper>
          );
        break;
      case "management":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}management`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="management"
              toolsKey="youthManagement"
            >
              <YouthManagementPart />
            </PathFormWrapper>
          ) : (
            <PathFormWrapper
              form={`${pathType}management`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="management"
              toolsKey="adultsManagement"
            >
              <AdultsManagementPart />
            </PathFormWrapper>
          );
        break;
      case "resources":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}resources`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="resources"
              toolsKey="youthResources"
            >
              <YouthResourcesPart />
            </PathFormWrapper>
          ) : (
            <PathFormWrapper
              form={`${pathType}resources`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="resources"
              toolsKey="adultsResources"
            >
              <AdultsResourcesPart />
            </PathFormWrapper>
          );
        break;
      case "people":
        formContent =
          pathType === PathType.YOUTH ? (
            <PathFormWrapper
              form={`${pathType}people`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="YOUTH"
              partKey="people"
              toolsKey="youthPeople"
            >
              <YouthPeoplePart />
            </PathFormWrapper>
          ) : (
            <PathFormWrapper
              form={`${pathType}people`}
              commentEdit={commentEdit}
              orgId={currentOrg.toString()}
              auditRequest={activeRequest}
              enrollment={enrollment}
              clubMode={clubMode}
              readOnlyMode={readOnlyMode}
              pathType="ADULTS"
              partKey="people"
              toolsKey="adultsPeople"
            >
              <AdultsPeoplePart />
            </PathFormWrapper>
          );
        break;
      default:
        break;
    }
    return (
      <div className="pathformversionwrapper">
        <CommentModal ratingMap={ratingMap} options={options} />
        <AttachmentAuditModal />
        {formContent}
      </div>
    );
  }
}

export default withTranslation("forms")(PathFormV3);
