import { PureComponent } from "react";
import footervincit from "../images/footerlogo.svg";
import suomisportlogo from "../images/suomisport-logo.svg";
import oklogo from "../images/oklogo.png";
import "./Footer.scss";
const version = process.env.REACT_APP_VERSION;

export class Footer extends PureComponent<{}> {
  render() {
    return (
      <div id="footer" className="footer">
        <div className="imagescontainer">
          <img src={oklogo} alt="" />
          <img src={footervincit} alt="" />
          <div className="suomisportlogo">
            <img src={suomisportlogo} alt="" />
            <div className="logotext">SEURAPALVELUT</div>
          </div>
        </div>
        <div className="versiontag">Versio: {version}</div>
      </div>
    );
  }
}
