import * as React from "react";
import { FormGroup, HelpBlock } from "react-bootstrap";
import FormFieldDescription from "./components/FormFieldDescription";
import ErrorBlock, { ErrorFieldType } from "./components/ErrorBlock";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { format as fnsFormat, parse as fnsParse } from "date-fns";
import DayPicker from "react-day-picker";
interface DateFieldProps {
  autoCapitalize?: "off" | "on";
  className?: string;
  disabled?: boolean;
  disableErrors?: boolean;
  flexDirection?: any;
  helpBlockPosition?: string;
  input: {
    value: any;
    onBlur(): void;
    onFocus(): void;
    onChange(value: any): void;
  };
  label?: string;
  labelClassName?: string;
  fieldsAsColumns?: boolean;
  formCtrlClassName?: string;
  helpText?: string;
  meta: {
    invalid: boolean;
    error: boolean;
    touched: boolean;
    dirty: boolean;
  };
  placeholder?: string;
  successMessage?: {
    isValid: boolean;
    message: string;
  };
  type?: string;
  wrapperClassNames?: string;
  maxlength?: number;
  field: any;
  onKeyDown?(event: any): void;
  formatter?(value: any): void;
}

const DateField: React.StatelessComponent<DateFieldProps> = ({
  autoCapitalize,
  className,
  disableErrors,
  flexDirection,
  formatter,
  formCtrlClassName,
  helpBlockPosition,
  label,
  labelClassName,
  wrapperClassNames,
  input,
  disabled,
  placeholder,
  helpText,
  meta,
  successMessage,
}) => {
  let usedClassNames = wrapperClassNames
    ? `${wrapperClassNames} formfield`
    : "formfield";
  usedClassNames = disabled ? `${usedClassNames} disabled` : usedClassNames;

  const usedLabelClassName = labelClassName
    ? `formlabel ${labelClassName}`
    : "formlabel";

  let blockTop;
  let blockBottom: any = null;
  const showLabel = { display: label ? "flex" : "none" };
  const displayedLabel = (
    <label style={showLabel} className={usedLabelClassName}>
      {label}
    </label>
  );

  if (helpBlockPosition === "top") {
    blockTop = (
      <ErrorBlock
        fieldType={ErrorFieldType.SINGLE}
        meta={meta}
        fallbackCmp={displayedLabel}
      />
    );
  } else {
    blockTop = displayedLabel;
  }

  if (helpBlockPosition === "bottom" || !helpBlockPosition) {
    if (!disableErrors && meta.error) {
      blockBottom = (
        <ErrorBlock fieldType={ErrorFieldType.SINGLE} meta={meta} />
      );
    } else if (
      !meta.error &&
      successMessage &&
      successMessage.isValid &&
      successMessage.message
    ) {
      blockBottom = <HelpBlock>{successMessage.message}</HelpBlock>;
    }
  }

  return (
    <FormGroup
      controlId={placeholder}
      style={{ flexDirection: flexDirection || "row" }}
      validationState={
        meta.invalid && meta.error && (meta.touched || meta.dirty)
          ? "error"
          : "success"
      }
      className={formCtrlClassName}
    >
      <div className="fieldlabel">
        {blockTop}
        <FormFieldDescription>{helpText}</FormFieldDescription>
      </div>
      <div className={usedClassNames}>
        <DayPickerInput
          formatDate={(date, format, locale) =>
            fnsFormat(date, format, { locale })
          }
          parseDate={(str, format, locale) => {
            const parsed = fnsParse(fnsFormat(str, format, { locale }));
            if ((DayPicker.DateUtils as any).isDate(parsed)) {
              return parsed;
            }
            return undefined;
          }}
          format="DD.MM.YYYY"
          placeholder={placeholder}
          inputProps={{ ...input, disabled: disabled }}
          value={input.value}
        />
        {blockBottom}
      </div>
    </FormGroup>
  );
};

DateField.defaultProps = {
  successMessage: {},
} as Partial<DateFieldProps>;

export default DateField;
