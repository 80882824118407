import { ClubInformation } from "../../model";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import "./ClubPageHeader.scss";

interface ownProps {
  club: ClubInformation;
}

export const ClubPageHeader = (props: ownProps) => {
  const { club } = props;
  if (!club) {
    return <LoadingSpinner />;
  }
  return (
    <div className="club-page-header-container">
      <h1 className="club-page-header-club-name">{club.name}</h1>
      <div className="club-page-header-federation-name">
        {club.federationName}
      </div>
    </div>
  );
};
